import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ReactDOM from 'react-dom';
import Header from './Header';
import Sidebar from './Sidebar';
import Dashboard from './Dashboard';
import Package from './Package';
import Profile from './Profile';
import Signal from './Signal';
import Hold from './Hold';
import Temp from './Temp';
import Wallet from './Wallet';
import Notfy from './Notfy';
import News from './News';
import P_404 from './P_404';
import { Toaster } from 'react-hot-toast';
import * as H1 from '../Helper/H1';
import Send from '../Helper/Send';

export default class Main extends React.Component {


    constructor(props) {
        super(props);
        this.state = {

        }
    }


    componentDidMount() {

        // H1.demo_mod().then(function (res) { if(!res){
          
        // }})

    }



    render() {
        
        return (
            

            
            <div id="main-wrapper">

            <Header />
        

                <Sidebar />

                <Toaster
                position="top-center"
                toastOptions={{
                    duration: 3000,
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    ariaProps: {
                        role: 'status',
                        'aria-live': 'polite',
                      },
                  }}
                />

                

                <div className="content-body">


                    <Routes>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/plan" element={<Package />} />
                        <Route path="/Profile" element={<Profile />} />
                        <Route path="/Signal" element={<Signal />} />
                        <Route path="/Wallet" element={<Wallet />} />
                        <Route path="/Hold" element={<Hold />} />
                        <Route path="/Notification" element={<Notfy />} />
                        <Route path="/News" element={<News />} />
                        <Route path="*" element={<P_404 />} />
                    </Routes>


                </div>


            </div>





        )
    }
}


