import React from 'react';
import * as H1 from '../Helper/H1';
import Send from '../Helper/Send';
import $ from "jquery";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { Scrollbar } from "react-scrollbars-custom";



export default class News extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            news: null,
            voice: null,
            page_num: {
                news: 1,
                voice: 1,
            },
            total_c: null,


        }
    }

    componentDidMount() {

        const self = this;
        let loader_1 = H1.notfy_loading();
        Send('page/news', null).then(function (res) {
            if (res) {
                self.setState({ voice: res.data.voice });
                self.setState({ news: res.data.news });
                self.setState({ total_c: { c_n: res.data.count.n_count, c_v: res.data.count.v_count } });
                H1.notfy_dismiss(loader_1);
            }
        });

    }



    /* -------------------------------- more_data ------------------------------- */
    more_data = (e) => {

        const self = this;
        let type = e.currentTarget.dataset.type;

        let data = {
            page_num: this.state.page_num[type],
            type: type,
        };
        let loader_1 = H1.notfy_loading();
        Send('page/news/more', data).then(function (res) {
            if (res) {

                if (data.type === "news") {
                    self.setState({
                        news: self.state.news.concat(res.data.more)
                    })

                    self.setState({
                        page_num: {
                            ...self.state.page_num,
                            news: data.page_num + 1
                        }
                    })

                }
                else if (data.type === "voice") {
                    self.setState({
                        voice: self.state.voice.concat(res.data.more)
                    })

                    self.setState({
                        page_num: {
                            ...self.state.page_num,
                            voice: data.page_num + 1
                        }
                    })

                }



                H1.notfy_dismiss(loader_1);

            }
        });

    }


    /* -------------------------------- change filter xs ------------------------------- */
    change_filter_xs = (e) => {

     $('.change_filter_xs_btn').removeClass('active');
     e.target.classList.add('active');

     $('.xs_show').removeClass("en");
     $(e.currentTarget.dataset.filter).addClass("en");

    }


    render() {

        H1.page_name("خبرنامه");

        


        return (


            <div className="container-fluid news_page">

                
                    <ul className="nav border-0 nav-tabs justify-content-center w-100 mb-4 news_xs_filter d-md-none" >
                        <li className="nav-item col-6">
                            <a className="w-100 btn btn-outline-primary px-2 text-primary change_filter_xs_btn active" data-filter="#news_xs_row" onClick={this.change_filter_xs}><i class="fas fa-arrow-alt-circle-down "></i> خبرنامه</a>
                        </li>
                        <li className="nav-item col-6">
                            <a className="w-100 btn btn-outline-primary px-2 text-primary change_filter_xs_btn" data-filter="#voice_xs_row" onClick={this.change_filter_xs}><i class="fas fa-arrow-alt-circle-down "></i> پادکست</a>
                        </li>
                    </ul>

                <div className="row">



                    <div className="col-md-7 col-12 xs_show en" id="news_xs_row">
                        {this.state.news &&

                            <div className="card">
                                <div className="card-header border-bottom">
                                    <h4> <i className="fas fa-newspaper mr-2 text-muted"></i> آخرین اخبار   </h4>
                                    {/* <span className="float-right text-right"> { this.state.total_c && this.state.total_c.c_n} مورد </span> */}
                                </div>

                                <div className="card-body">
                                    <div className="widget-timeline ">
                                        <Scrollbar
                                            style={{ width: 100 + '%', height: 100 + 'vh' }}
                                        >
                                            <ul className="pr-3">
                                                {this.state.news.map((item, index) => [

                                                    <li>

                                                        <div className="audio_box rounded p-3 text-muted mb-3">

                                                            <span className="float-right text-right">
                                                                {/* {item.created_at}  */}
                                                                <small>{item.date_diff} </small>
                                                            </span>

                                                            <h6 className="mb-2"> 
                                                             {/* <span className="badge py-0 badge-light rounded mr-2"> {index + 1}</span>  */}
                                                              {item.title}</h6>
                                                            <hr />
                                                            <div className="ws_bs">
                                                                {item.text}
                                                            </div>
                                                        </div>


                                                    </li>

                                                ])}


                                            </ul>

                                            {this.state.total_c && this.state.news.length < this.state.total_c.c_n &&

                                                <button id="more_news_btn" className="btn py-2 btn-outline-primary mx-auto d-block mb-1" data-type="news" onClick={this.more_data}>بیشتر ...</button>
                                            }

                                        </Scrollbar>


                                    </div>
                                </div>
                            </div>

                        }
                    </div>

                    <div className="col-md-5 col-12 xs_show" id="voice_xs_row">
                        {this.state.voice &&
                            <div className="card">
                                <div className="card-header border-bottom">
                                    <h4> <i className="fas fa-microphone mr-2 text-muted"></i> پادکست  بیت باز</h4>
                                    {/* <span className="float-right text-right"> { this.state.total_c && this.state.total_c.c_v} مورد </span> */}
                                </div>

                                <div className="card-body">
                                    <div className="widget-timeline ">
                                        <Scrollbar
                                            style={{ width: 100 + '%', height: 100 + 'vh' }}
                                        >
                                            <ul className="pr-3">


                                                {this.state.voice.map((item, index) => [

                                                    <li>




                                                        <div className="audio_box rounded p-3 text-muted mb-3">


                                                            <span className="float-right text-right">
                                                                {/* {item.created_at}  */}
                                                                <small>{item.date_diff} </small>
                                                            </span>

                                                            <h6 className="mb-2">
                                                                {/* <span className="badge py-0 badge-light rounded mr-2"> {index + 1}</span> */}
                                                             {item.title}</h6>

                                                            <AudioPlayer
                                                                className='ltr c_audio'
                                                                preload='metadata'
                                                                volume={1}
                                                                src={"https://bitbaaz.com/" + item.file}
                                                                autoPlay={false}
                                                                loop={false}
                                                                hasDefaultKeyBindings={false}
                                                                showJumpControls={false}
                                                                showDownloadProgress={false}
                                                                layout='horizontal-reverse'
                                                                customAdditionalControls={[]}
                                                                customVolumeControls={[]}

                                                            />

                                                            {/* <audio controls src={"https://bitbaaz.com/" + item.file}></audio> */}





                                                        </div>
                                                    </li>

                                                ])}

                                            </ul>
                                            {this.state.total_c && this.state.voice.length < this.state.total_c.c_v &&

                                                <button id="more_voice_btn" className="btn py-2 btn-outline-primary mx-auto d-block mb-1" data-type="voice" onClick={this.more_data}>بیشتر ...</button>
                                            }
                                        </Scrollbar>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>


        )
    }
}

