import React from 'react';
import { NavLink } from 'react-router-dom';



export default class Sidebar extends React.Component {



  render() {
    return (




      <div className="deznav d-none d-sm-block">
        <div className="deznav-scroll">

          <ul className="metismenu" id="menu">

            <li>
              <NavLink className="ai-icon" aria-expanded="false" exact="true" to="/">
              <i className="far fa-home-alt"></i>
              </NavLink>
            </li>

            <li>
              <NavLink className="ai-icon" aria-expanded="false" exact="true" to="/signal">
              <i className="far fa-signal-stream"></i>
              </NavLink>
            </li>


            <li>
              <NavLink className="ai-icon" aria-expanded="false" exact="true" to="/Hold">
              <i className="far fa-shopping-basket"></i>
              </NavLink>
            </li>

            <li>
              <NavLink className="ai-icon" aria-expanded="false" exact="true" to="/Wallet">
              <i className="far fa-wallet"></i>
              </NavLink>
            </li>

            <li>
              <NavLink className="ai-icon" aria-expanded="false" exact="true" to="/News">
              <i className="far fa-newspaper"></i>
              </NavLink>
            </li>

            <li>
              <NavLink className="ai-icon" aria-expanded="false" exact="true" to="/Notification">
              <i className="far fa-bell"></i>
              </NavLink>
            </li>




          </ul>

        </div>
      </div>



    )
  }
}

