import React from 'react';
import * as H1 from '../Helper/H1';
import Send from '../Helper/Send';
import $ from "jquery";
import { formatCurrency } from "@coingecko/cryptoformat";
import { Scrollbar } from "react-scrollbars-custom";
export default class Hold extends React.Component {

    /* -------------------------------------------------------------------------- */
    /*                                 constructor                                */
    /* -------------------------------------------------------------------------- */

    constructor(props) {
        super(props);

        this.state = {
            hold: [],
            crypto_price: {},
            extra_modal: {},
            trade_modal: null,
            filter: "smart_contract"
        }
    }


    /* -------------------------------------------------------------------------- */
    /*                              componentDidMount                             */
    /* -------------------------------------------------------------------------- */


    componentDidMount() {



        let loader_1 = H1.notfy_loading();
        const self = this;

        Send('page/hold', null).then(function (res) {
            if (res) {
                self.setState({ crypto_price: res.data.crypto_price });
                self.setState({ hold: res.data.hold });
                H1.notfy_dismiss(loader_1);
            }
        });

    }


    /* -------------------------------------------------------------------------- */
    /*                              componentWillUnmount                          */
    /* -------------------------------------------------------------------------- */
    componentWillUnmount() {
    }


    /* ------------------------------ change_filter ----------------------------- */
    change_filter = (e) => {
        H1.notfy_remove();


        $('.filter_option .btn').removeClass('active');
        e.target.classList.add('active');



        this.setState({ filter: e.currentTarget.dataset.filter_val });


        H1.notfy_icon("اعمال تغیرات", '⚙️');

    }



    /* ------------------------------ add_to_alert ------------------------------ */
    add_to_alert = (e) => {


        $(e.target).attr('disabled', true);

        const self = this;
        let data = {
            hold_id: e.currentTarget.dataset.hold_id,
            action: e.currentTarget.dataset.type,
        };


        H1.notfy_promise(Send('page/hold/alert', data)).then(function () {
            self.componentDidMount();
            $(e.target).attr('disabled', false);
        });
    }

    /* ------------------------------- open_modal ------------------------------- */
    open_extra_modal = (e) => {
        this.setState({ extra_modal: this.state.hold[e.currentTarget.dataset.index] });
    }
    /* ------------------------------- open_modal ------------------------------- */
    open_trade_modal = (e) => {
        this.setState({ trade_modal: this.state.hold[e.currentTarget.dataset.index] });
        $("#add_to_trade_price").val("")
        $("#add_to_trade_size").val("")
    }

    /* ------------------------------ add_to_trade ------------------------------ */

    add_to_hold = (e) => {
        const self = this;

        let data = {
            action: 'add',
            hold_id: this.state.trade_modal.id,
            name: this.state.trade_modal.name,
            type: this.state.trade_modal.type,
            add_to_trade_price: $("#add_to_trade_price").val(),
            add_to_trade_size: $("#add_to_trade_size").val(),
        };


        !data.add_to_trade_price ? H1.alert_warning('قیمت ورود را وارد نمایید.')
            : !data.add_to_trade_size ? H1.alert_warning('مقدار وارد شده صحیح نمیباشد.')

                : H1.notfy_promise(Send('page/wallet/hold', data, true)).then(function () {
                    self.componentDidMount();
                    $('.trade_modal').trigger('click')


                    $("#add_to_trade_price").val("")
                    $("#add_to_trade_size").val("")
                });
    }


    /* -------------------------------------------------------------------------- */
    /*                                   render                                   */
    /* -------------------------------------------------------------------------- */

    render() {
        $(document).ready(function () {
        });

        H1.page_name("سبد خرید");

        return (

            <div className="container-fluid signal_page hold_page">

                       

                <div className="filter_option pt-4 text-center">


                    <div className="row justify-content-center">


                        <div className="box card position-relative type_time" >
                          <h4 className="dark-bg-dark"><i className="far fa-level-down  mr-1"></i>  نوع پروژه  <i className="far fa-level-down fa-flip-horizontal ml-1"></i></h4>
                            <div className="btn-group mb-2 btn-group-lg card-body justify-content">
                                <button onClick={this.change_filter} data-filter_val="smart_contract" className="btn btn-outline-primary px-2 active" type="button">
                                 <span className="count">{this.state.hold.filter( hold =>  hold.type === 'smart_contract').length }</span> SMART CONTRACT</button>
                                 <button onClick={this.change_filter} data-filter_val="nft_meta" className="btn btn-outline-primary px-2" type="button">
                                 <span className="count">{this.state.hold.filter( hold =>  hold.type === 'nft_meta').length }</span> NFT/METAVERS  </button>
                                <button onClick={this.change_filter} data-filter_val="web3" className="btn btn-outline-primary px-2" type="button">
                                 <span className="count">{this.state.hold.filter( hold =>  hold.type === 'web3').length }</span> WEB3  </button>
                                <button onClick={this.change_filter} data-filter_val="defi" className="btn btn-outline-primary px-2" type="button">
                                 <span className="count">{this.state.hold.filter( hold =>  hold.type === 'defi').length }</span> DEFI   </button>
                                <button onClick={this.change_filter} data-filter_val="privecy" className="btn btn-outline-primary px-2" type="button">
                                 <span className="count">{this.state.hold.filter( hold =>  hold.type === 'privecy').length }</span> PRIVECY   </button>
                                 <button onClick={this.change_filter} data-filter_val="platform" className="btn btn-outline-primary px-2" type="button">
                                 <span className="count">{this.state.hold.filter( hold =>  hold.type === 'platform').length }</span> PLATFORM   </button>
                            </div>
                        </div>


                    </div>

                    <i className="far fa-bookmark mr-1 text-org"></i> 
                    {
                    this.state.filter === 'smart_contract' ?
                    'بستر هایی برای ساخت اپلیکیشن های غیر متمرکز'
                    :this.state.filter === 'nft_meta' ?
                    'دنیایی مجازی که در آن قابلیت های زندگی واقعی رو دارید'
                    :this.state.filter === 'web3' ?
                    'نسل جدید اینترنت بصورت غیرمتمرکز،باز  و  کاربردی تر'
                    :this.state.filter === 'defi' ?
                    'بستر های امور مالی غیر متمرکز(تامین نقدینگی و وام دهی و …)'
                    :this.state.filter === 'privecy' &&
                    'بستر های تامین امنیت و حریم شخصی'
                    }

      

                </div>


                <hr />

                    { localStorage.getItem('d_mod') == true &&
                        <div className="demo_mod_baneer"> <i className="far fa-exclamation-triangle mr-2"></i> به دلیل استفاده از نسخه رایگان اپلیکیشن، تعداد سبد های مورد نمایش محدود شده است. </div>
                    }
               
                <div className="row mt-2 signal_sec">



                    {

                        this.state.crypto_price &&
                        this.state.hold.filter(hold => hold.type === this.state.filter).map((item, index) => [


                            <div className="col-xl-4 col-lg-6 col-md-6 signal_col mb-3">
                                <div className="card text-white signal_row text-left">
                                    <div className="box text-left ">
                                        <div className='card-header text-left'>


                                            <div className="name col-lg-8 col-md-6 col-6">
                                                <img src={`https://bitbaaz.com/img/coins/${item.name}.png`} />

                                                <span className="mt-2 mr-2 d-inline-block">{item.name}</span>

                                            </div>

                                            <div className="col-lg-4 col-md-6 col-6 mini-action-box text-left px-xs-0">




                                                {
                                                    !item.in_trade  ?
                                                     <button onClick={this.open_trade_modal} data-index={item.index} data-toggle="modal" data-target="#add_trade_modal" title="افزودن به معاملات" data-step="hb9"><i className="far fa-plus-circle"></i></button>
                                                    :<button disabled className="disabled" title="در سبد معامله موجود است" data-step="hb9"><i className="far fa-plus-circle"></i></button>
                                                }

                                                {
                                                    !item.alert ?
                                                        <button onClick={this.add_to_alert} data-hold_id={item.id} data-type="add" title="فعال کردن اعلان" data-step="hb8"> <i className="far fa-bell silent"></i> </button>
                                                        : <button onClick={this.add_to_alert} data-hold_id={item.id} data-type="remove" title="غیر فعال کردن اعلان" data-step="hb8"><i className="far fa-bell-slash silent"></i> </button>
                                                }




                                            </div>

                                        </div>

                                        <div className="card-body text-left info_box px-3 py-4 ">



                                            <div className="d-flex flex-wrap text-center mt-3">




                                                <div className="col-4 px-2 mb-2 mb-2" data-step="hb1">
                                                    <div className="key"> رتبه  </div>
                                                    <div className="val"> {item.rank}  </div>
                                                </div>

                                                <div className="col-4 px-2 mb-2" data-step="hb2">
                                                    <div className="key">  تورم  </div>
                                                    <div className="val">  %{item.inflation}  </div>
                                                </div>
                                                <div className="col-4 px-2 mb-2" data-step="hb3">
                                                    <div className="key"> امتیاز </div>
                                                    <div className="val"> {item.rate} </div>
                                                </div>

                                                <div className="col-4 px-2 mb-2 chaeck-type" data-step="hb4">
                                                    <div className="key"> امکان ماین  </div>
                                                    <div className="val">
                                                        {item.minable ?
                                                            <i className="fas fa-badge-check true"></i>
                                                            : <i className="fas fa-ban false"></i>
                                                        }

                                                    </div>
                                                </div>

                                                <div className="col-4 px-2 mb-2 chaeck-type" data-step="hb5">
                                                    <div className="key">  stake  </div>
                                                    <div className="val">

                                                        {item.stake ?
                                                            <i className="fas fa-badge-check true"></i>
                                                            : <i className="fas fa-ban false"></i>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-4 px-2 mb-2" data-step="hb6">
                                                    <div className="key">  سرمایه گذاری </div>
                                                    <div className="val"> %{item.allocation} </div>
                                                </div>


                                                <div className="col-6 px-2 mb-2" data-step="hb6">
                                                    <div className="key"> زمان معرفی</div>
                                                    <div className="val">  {item.created_at} </div>
                                                </div>

                                                <div className="col-6 px-2 mb-2" data-step="hb6">
                                                    <div className="key">  آخرین بروزرسانی </div>
                                                    <div className="val">  {item.date_diff}   </div>
                                                </div>


                                                <button data-step="hb7" className="btn btn-primary extra_btn" onClick={this.open_extra_modal} data-toggle="modal" data-target="#extra_modal" data-index={item.index}>
                                                    توضیحات 
                                                </button>


                                            </div>


                                        </div>


                                    </div>
                                </div>
                            </div>


                        ])

                    }






                    <div className="modal fade trade_modal extra_modal c-modal" id="extra_modal">
                        <div className="modal-dialog modal-dialog-centered modal-dialog " role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title m-auto"> سبد خرید {this.state.extra_modal.name}  </h5>
                                    <button type="button" className="close" data-dismiss="modal"><span>&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">


                                    {this.state.extra_modal.dis &&
                                        <div className="form-group">


                                            <div className="form-control bg-light txt-area" disabled>
                                                <Scrollbar
                                                    style={{ width: 100 + '%', height: 100 + '%' }}
                                                >
                                                    {this.state.extra_modal.dis}

                                                </Scrollbar>
                                            </div>


                                            <hr />
                                        </div>

                                    }
                                    <div className="pt-1">



                                        <div className="lab2">
                                            قیمت فعلی:

                                            <b className="px-2">
                                                {
                                                    this.state.extra_modal.name &&
                                                    formatCurrency(Number(this.state.crypto_price[this.state.extra_modal.name].price), "USD", "en", true)
                                                }
                                                $
                                            </b>

                                        </div>
                                        <div className="clearfix"></div>
                                        <div className="progress position-relative"
                                            data-buy_price={this.state.extra_modal.zone_buy_more   = Number(  Number(this.state.extra_modal.zone_buy)  - (( Number(this.state.extra_modal.zone_buy)  * 20) / 100) )}
                                            data-sell_price={this.state.extra_modal.zone_sell_more = Number(  Number(this.state.extra_modal.zone_sell) + (( Number(this.state.extra_modal.zone_sell) * 20) / 100))}
                                        >


                                            <div className="progress-bar bg-danger sell_sec" style={{
                                                width:
                                                    this.state.extra_modal.name &&
                                                    Number(100 - (( Number(this.state.extra_modal.zone_sell) / (this.state.extra_modal.zone_buy_more + this.state.extra_modal.zone_sell_more)) * 100)).toFixed(1)
                                                    + '%'
                                            }}></div>
                                            <div className="progress-bar bg-success buy_sec" style={{
                                                width:
                                                    this.state.extra_modal.name &&
                                                    Number(( Number(this.state.extra_modal.zone_buy) / (this.state.extra_modal.zone_buy_more + this.state.extra_modal.zone_sell_more)) * 100).toFixed(1)
                                                    + '%'
                                            }}></div>

                                            <div className="progress-bar curent_price" style={{
                                                width:
                                                    this.state.extra_modal.name &&
                                                    Number((this.state.crypto_price[this.state.extra_modal.name].price / (this.state.extra_modal.zone_buy_more + this.state.extra_modal.zone_sell_more)) * 100).toFixed(1)
                                                    + '%'
                                            }} role="progressbar"> <span>

                                                    <i className="fas fa-arrow-alt-from-top"></i>  </span></div>
                                        </div>
                                        <div className="px-2 mt-3 lab">
                                            <span className="float-right">${this.state.extra_modal.zone_buy} <i className="far fa-greater-than-equal px-1 text-success"></i>  خرید </span>
                                            <span className="">فروش  <i className="far fa-less-than-equal px-1 text-danger"></i> {this.state.extra_modal.zone_sell}$  </span>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                    {this.state.trade_modal &&
                    <div className="modal fade trade_modal" id="add_trade_modal">
                        <div className="modal-dialog modal-dialog-centered modal-dialog " role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title m-auto">
                                        <img className="mr-2" src={`https://bitbaaz.com/img/coins/${this.state.trade_modal.name}.png`} width='24px' />
                                        افزودن سبد خرید به معاملات من </h5>
                                    <button type="button" className="close" data-dismiss="modal"><span>&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    <div className="alert alert-outline-info px-3 px-3">
                                        <i className="fas fa-info-circle mr-1"></i>
                                        برای ورود به این معامله بهتر است از {this.state.trade_modal.allocation}% سرمایه خود استفاده کنید.
                                    </div>

                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">  مقدار خرید  </span>
                                        </div>
                                        <input type="number" id="add_to_trade_size" className="form-control ltr font-18" autoComplete="off" />
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">  <span id="add_to_trade_size_key">{this.state.trade_modal.name}</span>  </span>
                                        </div>
                                    </div>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">قیمت خرید:</span>
                                        </div>
                                        <input type="number" id="add_to_trade_price" className="form-control ltr font-18" autoComplete="off" />
                                        <div className="input-group-prepend">
                                            <span className="input-group-text"> $</span>
                                        </div>
                                    </div>


                                </div>
                                <div className="modal-footer">
                                    <button type="button" id="add_to_trade_cancle" className="btn btn-danger light mr-auto" data-dismiss="modal">انصراف</button>
                                    <button type="button" id="add_to_trade_action" onClick={localStorage.getItem('d_mod') == true ? H1.demo_mod : this.add_to_hold} className="btn btn-primary px-5" > افزودن  <i className="fas fa-plus-hexagon ml-1"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                     }









                </div>
            </div>



        )
    }
}

