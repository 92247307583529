import React from 'react';
import * as H1 from '../Helper/H1';
import Send from '../Helper/Send';
import $ from "jquery";
import { formatCurrency } from "@coingecko/cryptoformat";
import { Scrollbar } from "react-scrollbars-custom";
import ReactLoading from 'react-loading';
import ReactECharts from 'echarts-for-react';
// import C3Chart from 'react-c3js';
// import 'c3/c3.css';

let size = {
    width: 550
};


if ($(window).width() < 767) {
     size = {
        width: 300
    };
    console.log(size);
}
else{
     size = {
        width: 550
    };
}

const tooltip = {
    show: false
};


const bar = {
    width: 10
};
const grid = {
    y: {
        lines: [{ value: 0 }]
    },

}
const legend = {
    show: true
};


export default class Wallet extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            trade_list: null,
            hold_list: null,
            crypto_price: null,
            trade_modal: null,
            hold_single_modal: null,
            hold_single_trans: null,
            hold_performance: null,
            hold_chart_1: null,
            hold_chart_2: null,
            hold_chart_3: null,
            total: {
                total_trade_stus_1: null,
                total_trade_stus_3: null,
                total_hold_stus_1: null,
                total_hold_stus_3: null,
            },
            wallet: null,
            wallet_total_price: null,


        }
    }



    componentDidMount() {
        H1.notfy_loading();

        const self = this;
        Send('page/wallet', null).then(function (res) {
            if(res){
                self.setState({ wallet: res.data.wallet });
                self.setState({ crypto_price: res.data.crypto_price });
                self.calc(res.data.trade_list, res.data.hold);
                
            }
                H1.notfy_dismiss();
        });

    }


    calc = (data_trade, data_hold) => {

        let total_trade_stus_1 = {
            pnl: 0, roe: 0
        };
        let total_trade_stus_3 = {
            pnl: 0, roe: 0
        };
        let total_hold_stus_1 = {
            pnl: 0, roe: 0
        };

        let total_hold_stus_3 = {
            pnl: 0, roe: 0
        };


        let all_hold_margin_stus_1 = 0;
        let all_trade_margin_stus_1 = 0;
        let all_trade_margin_stus_3 = 0;

        /* --------------------------- data_trade.forEach --------------------------- */

        if (data_trade.length) {
            data_trade.forEach((item, index) => {
                data_trade[index].index = index;



                if (Number(item.stus) == 3) {
                    data_trade[index].pnl = H1.PNL(item.price_exit, item.price_entry, item.size, item.pos);
                    total_trade_stus_3.pnl += item.pnl;
                    all_trade_margin_stus_3 += Number(item.margin);

                }
                else if (Number(item.stus) == 1) {
                    data_trade[index].pnl = H1.PNL(this.state.crypto_price[item.signal_name_by].price, item.price_entry, item.size, item.pos);
                    total_trade_stus_1.pnl += item.pnl;
                    all_trade_margin_stus_1 += Number(item.margin);

                }

                data_trade[index].roe = H1.ROE(item.pnl, item.margin);

            });
            this.setState({ trade_list: data_trade });

            total_trade_stus_1.roe = H1.ROE(total_trade_stus_1.pnl, all_trade_margin_stus_1);
            total_trade_stus_3.roe = H1.ROE(total_trade_stus_3.pnl, all_trade_margin_stus_3);



            this.setState({
                total: {
                    ...this.state.total,
                    total_trade_stus_3: total_trade_stus_3,
                    total_trade_stus_1: total_trade_stus_1
                }
            })

        }
        // let all_hold_margin_stus_3 = 0 ;

        /* --------------------------- data_hold.forEach --------------------------- */
        if (data_hold.length) {


            let hold_chart_1 = {
                smart_contract: 0,
                nft_meta: 0,
                web3: 0,
                defi: 0,
                privecy: 0,
                platform: 0,
            };
            let data_chart_2_columns = [];
            let data_chart_3_columns = [];


            data_hold.forEach((item, index) => {
                data_hold[index].index = index;
                data_hold[index].pnl = H1.PNL(this.state.crypto_price[item.name].price, item.price_entry, item.size, 'B');
                data_hold[index].roe = H1.ROE(item.pnl, item.margin)
                // if (Number(item.stus) == 3) {
                //     total_hold_stus_3.pnl += item.pnl;
                //     total_hold_stus_3.roe += item.roe;
                // }
                if (Number(item.stus) == 1) {
                    total_hold_stus_1.pnl += item.pnl;
                    all_hold_margin_stus_1 += Number(item.margin);
                }

                hold_chart_1[[item.type]] += Number(parseInt(item.margin));

                if(item.margin > 0){
                data_chart_2_columns.push(
                    { value: Number(parseInt(item.margin)), name: item.name }
                    );
                }

                if (item.pnl_u) {
                    if(item.pnl_u < 0){

                        data_chart_3_columns.push(
                            { value: Number(parseInt(item.pnl_u)), name: item.name, itemStyle: {color: '#f44336'} }
                            );
                        }

                        else{
                            data_chart_3_columns.push(
                                { value: Number(parseInt(item.pnl_u)), name: item.name }
                            );
                            
                        }

                }
            });




            let hold_performance_best_count = data_hold[0].pnl;
            let hold_performance_worst_count = data_hold[0].pnl;
            let hold_performance_best, hold_performance_worst;

            data_hold.forEach((item, index) => {

                if (item.pnl >= hold_performance_best_count && item.pnl > 0) {
                    hold_performance_best_count = item.pnl;
                    hold_performance_best = item.index;
                }

                if (item.pnl <= hold_performance_worst_count) {
                    hold_performance_worst_count = item.pnl;
                    hold_performance_worst = item.index;
                }

            });


            total_hold_stus_1.roe = H1.ROE(total_hold_stus_1.pnl, all_hold_margin_stus_1);

            this.setState({ hold_list: data_hold });



            this.setState({
                hold_performance: {
                    best: hold_performance_best,
                    worst: hold_performance_worst
                }
            });

            this.setState({
                total: {
                    ...this.state.total,
                    total_hold_stus_1: total_hold_stus_1
                }
            })



            let data_chart_1 = {

                    legend: {
                      bottom : -30,
                    },
                      tooltip: {
                      trigger: 'item',
                      formatter: '{b} <br/>  $ {c} ({d}%)'
                    },

                    series: [
                      {
                        type: 'pie',
              
                        radius: ['40%', '70%'],
                       
                        itemStyle: {
                          borderRadius: 4
                        },
                        data: [
                          { value: hold_chart_1['smart_contract'], name: 'SMART CONTRACT' },
                          { value: hold_chart_1['nft_meta'], name: 'NFT/METAVERS' },
                          { value: hold_chart_1['web3'], name: 'WEB3' },
                          { value: hold_chart_1['defi'], name: 'DEFI' },
                          { value: hold_chart_1['privecy'], name: 'PRIVECY' },
                          { value: hold_chart_1['platform'], name: 'PLATFORM' }
                        ]
                      }
                    ]
                  };


     

                // columns: [
                //     ['SMART CONTRACT', hold_chart_1['smart_contract']],
                //     ['NFT/METAVERS ', hold_chart_1['nft_meta']],
                //     ['WEB3', hold_chart_1['web3']],
                //     ['DEFI', hold_chart_1['defi']],
                //     ['PRIVECY', hold_chart_1['privecy']],
                //     ['PLATFORM', hold_chart_1['platform']],
                // ],
                // type: 'donut',
                // // onclick: function (d, i) { console.log("onclick", d, i); },



                // option = 





            let data_chart_2 = {

                    legend: {
                        bottom : -5,
                        padding: [0, 0,5,0]
                      },
                        tooltip: {
                        trigger: 'item',
                        formatter: '{b} <br/>  $ {c} ({d}%)',
                        className: 'ltr',
                        textStyle:{
                            fontFamily: 'Yekan_Bakh_FaNum',
                            align: 'left',
                            className: 'ltr',

                        }
                      },
  
                      series: [
                        {
                          type: 'pie',
                
                          radius: ['40%', '70%'],
                       
                         
                          itemStyle: {
                            borderRadius: 4
                          },
                          data: data_chart_2_columns
                        }
                      ]
                 
            };

            let data_chart_3 = {



                // legend: {
                //     bottom : -5,
                //     padding: [0, 0,5,0]
                //   },
                //     tooltip: {
                //     trigger: 'item',
                //     formatter: '{b} <br/>  $ {c} ({d}%)'
                //   },
                //   xAxis: {
                //     type: 'category',
                //     data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                //   },
                //   yAxis: {
                //     type: 'value'
                //   },
                //   series: [
                //     {
                //      type: 'bar',
        
                //       data: data_chart_3_columns
                //     }
                //   ]


                legend: {},
                  xAxis: {
                    className: 'ltr',
                         axisLabel: {
                        fontFamily: 'Yekan_Bakh_FaNum'
                    }
                  },
                  yAxis: {
                    className: 'ltr',
                    axisLabel: {
                        formatter: '{value} $',
                        align: 'right',
                        fontFamily: 'Yekan_Bakh_FaNum',
                        className: 'ltr',

                      }
                      
                  },
                  tooltip: {
                    position: 'top',
                    formatter: '{b} <br/>  $ {c} ',
                    className: 'ltr',
                    align: 'left',
                    textStyle:{
                        fontFamily: 'Yekan_Bakh_FaNum',
                        align: 'left',
                    }
                  },
                  series: [
                    {
                        color: [
                            '#4caf50'
                          ],
                      symbolSize: 35,
                      data: data_chart_3_columns,
                      type: 'scatter',
                      label: {
                        show: true,
                        formatter: '{b}',
                        color:'#fff'
                      },
                    }
                  ]




                // columns: data_chart_3_columns,
                // type: 'bar',
                // bar: {
                //     width: {
                //         ratio: 0.5
                //     }
                // }
                // onclick: function (d, i) { console.log("onclick", d, i); },
            };



            this.setState({ hold_chart_1: data_chart_1 });
            this.setState({ hold_chart_2: data_chart_2 });
            this.setState({ hold_chart_3: data_chart_3 });


            this.setState({ wallet_total_price: total_trade_stus_1.pnl + total_trade_stus_3.pnl + total_hold_stus_1.pnl });




        }

    }





    trade_remove = (e) => {
        const self = this;


        let data = {
            signal_id: e.currentTarget.dataset.signal_id,
            action: "remove",
        };


        H1.alert_verify('warning', "سیگنال موردنظر حذف گردد؟").then(function (res2) {
            if (res2) {

                H1.notfy_promise(Send('page/wallet/trade', data)).then(function () {
                    self.componentDidMount();
                });

            }
        });



    }

    open_trade_edit_modal = (e) => {

        this.setState({ trade_modal: this.state.trade_list[e.currentTarget.dataset.signal_index] });

    }

    open_hold_single_modal = (e) => {


        H1.notfy_loading();

        let data = {
            hold_trade_id: e.currentTarget.dataset.hold_trade_id,
        };

        const self = this;
        Send('page/wallet/hold/trans', data, null).then(function (res) {

            self.setState({ hold_single_trans: res.data.hold_trans });

            H1.notfy_dismiss();
        });

        this.setState({ hold_single_modal: this.state.hold_list[e.currentTarget.dataset.hold_index] });
    }



    edit_to_trade = (e) => {


        const self = this;

        $(e.target).attr('disabled', true);


        let data = {
            action: 'edit',
            signal_id: this.state.trade_modal.id,
            signal_name_by: this.state.trade_modal.signal_name_by,
            signal_name_to: this.state.trade_modal.signal_name_to,
            level_count: this.state.trade_modal.level_count,
            add_to_trade_price: $("#add_to_trade_price").val(),
            add_to_trade_size: $("#add_to_trade_size").val(),
        };


        !data.add_to_trade_price ? H1.alert_warning('قیمت ورود را وارد نمایید.')
            : !data.add_to_trade_size ? H1.alert_warning('مقدار وارد شده صحیح نمیباشد.')

                : H1.notfy_promise(Send('page/wallet/trade', data)).then(function () {
                    self.componentDidMount();
                    $('.trade_modal').trigger('click')
                    $(e.target).attr('disabled', false);

                    $("#add_to_trade_price").val();
                    $("#add_to_trade_size").val();
                });

    }

    exit_to_trade = (e) => {

        const self = this;

        $(e.target).attr('disabled', true);


        let data = {
            action: 'exit',
            signal_id: this.state.trade_modal.id,
            signal_id: this.state.trade_modal.id,
            signal_name_by: this.state.trade_modal.signal_name_by,
            signal_name_to: this.state.trade_modal.signal_name_to,
            level_count: this.state.trade_modal.level_count,
            add_to_trade_price: this.state.trade_modal.price_entry,
            add_to_trade_size: this.state.trade_modal.size,
            exit_to_trade_price: $("#exit_to_trade_price").val(),
        };


        !data.exit_to_trade_price ? H1.alert_warning('قیمت خروج را وارد نمایید.')
            : H1.notfy_promise(Send('page/wallet/trade', data)).then(function () {
                self.componentDidMount();
                $('.trade_modal').trigger('click')
                $(e.target).attr('disabled', false);
                $("#exit_to_trade_price").val("");
            });

    }


    add_hold_trans = (e) => {

        const self = this;
        $(e.target).attr('disabled', false)


        let data = {
            add_hold_trans_id: this.state.hold_single_modal.id,
            add_hold_trans_name: this.state.hold_single_modal.name,
            add_hold_trans_price: $("#add_hold_trans_price").val(),
            add_hold_trans_size: $("#add_hold_trans_size").val(),
            add_hold_trans_type: $("#add_hold_trans_type").val(),
        };


        !data.add_hold_trans_price ? H1.alert_warning('قیمت ورود را وارد نمایید.')
            : !data.add_hold_trans_size ? H1.alert_warning('مقدار وارد شده صحیح نمیباشد.')
                : !data.add_hold_trans_type ? H1.alert_warning('نوع تراکنش را انتخاب نمایید.')
                    : data.add_hold_trans_type === "S" && data.add_hold_trans_size > this.state.hold_single_modal.size ? H1.alert_warning('مقدار فروش بیشتر از تعداد موجود در کیف پول است.')

                        : H1.notfy_promise(Send('page/wallet/hold/trans/add', data)).then(function () {
                            self.componentDidMount();
                            $('.trade_modal').trigger('click')
                            $(e.target).attr('disabled', false);
                            $('#add_trans_modal .trnas_type button').addClass('btn-dark');

                            $("#add_hold_trans_price").val("");
                            $("#add_hold_trans_size").val("");
                        });

    }

    change_add_trans_type = (e) => {

        $(e.target).parent().children().addClass('btn-dark');
        $('#add_hold_trans_type').val(e.currentTarget.dataset.val);
        $(e.target).removeClass('btn-dark');

        if (e.currentTarget.dataset.val === "S") {
            $("#totall_size_box").fadeIn(200);
        }
        else {
            $("#totall_size_box").hide();
        }



    }

    totall_size_select = (e) => {

        $("#add_hold_trans_size").val(e.currentTarget.dataset.val);

    }


    render() {

        $(document).ready(function () {


        });

        H1.page_name("کیف پول تست");

        return (

            <div className="container-fluid wallet_page">




                <div className="custom-tab-1">

                    <ul className="nav nav-tabs justify-content-center text-center border-0" role="tablist">
                        <li className="nav-item col-4" data-step="w1">
                            <a className="btn btn-outline-primary w-100 active" data-toggle="tab" href="#overview_tab"><i className="fas fa-arrow-alt-circle-down "></i> وضعیت من</a>
                        </li>
                        <li className="nav-item col-4" data-step="w2">
                            <a className="btn btn-outline-primary w-100" data-toggle="tab" href="#hold_tab"><i className="fas fa-arrow-alt-circle-down "></i> سبدهای خرید</a>
                        </li>
                        <li className="nav-item col-4" data-step="w3">
                            <a className="btn btn-outline-primary w-100" data-toggle="tab" href="#trade_tab"><i className="fas fa-arrow-alt-circle-down "></i>  معاملات  </a>
                        </li>

                    </ul>
                </div>



                <div className="tab-content pt-4">
                <hr className="hr-xs"/>


                    {/*  --------------------------------------------------------------------------*/
                /*                                Overview_tab                                 */
                /* --------------------------------------------------------------------------  */}
                    {this.state.wallet &&
                    <div className="tab-pane fade show active pt-4" id="overview_tab" role="tabpanel">
                        <div className="row">
                            <div className="col-lg-4 col-lg-4 col-md-6 col-12">
                                <div className="card-bx stacked card">
                                    <img src="images/card/card2.jpg" alt="" />
                                    <div className="card-info">
                                        <p className="fs-14 mb-1 text-white">ارزش کل:</p>
                                        <div className="d-flex justify-content-between">
                                            <h2 className="num-text text-white mb-5 font-w600">
                                                $
                                                {
                                                    this.state.wallet_total_price &&
                                                    H1.formatNumber(this.state.wallet_total_price.toFixed(2))
                                                }

                                       
                                            </h2>
                                            <svg width="55" height="34" viewBox="0 0 55 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="38.0091" cy="16.7788" r="16.7788" fill="white" fillOpacity="0.67"></circle>
                                                <circle cx="17.4636" cy="16.7788" r="16.7788" fill="white" fillOpacity="0.67"></circle>
                                            </svg>
                                        </div>
                                        <div className="d-flex">
                                            <div className="mr-4 text-white">
                                                <p className="fs-12 mb-1 op6">تاریخ عضویت:</p>
                                                <span>{this.state.wallet.created_at}</span>
                                            </div>
                                            <div className="text-white">
                                                <p className="fs-12 mb-1 op6">صاحب حساب:</p>
                                                <span>{localStorage.getItem('name')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            { this.state.trade_list &&
                            <div className="col-lg-3 col-lg-4 col-md-6 col-12">
                                    <div className="widget-stat card user_info_wal">
                                      <div className="card-body p-4">
                                        <div className="media">
                                            <span className="mr-3">
                                            <i className="fas fa-signal-stream text-org"></i>
                                            </span>
                                            <div className="media-body text-left">
                                                <h4 className="mb-0 text-dark">سیگنال</h4>
                                                <hr/>
                                                <h5 className="d-block mb-4">  فعال  <span className="px-3 float-right text-light bg-org text-info rounded"> { this.state.trade_list.filter( trade_list =>  trade_list.stus == 1).length }</span> </h5>
                                                <h5 className="d-block">  تمام شده <span className="px-3 float-right text-light bg-org text-info rounded">{ this.state.trade_list.filter( trade_list =>  trade_list.stus == 3).length } </span> </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                          }

                        { this.state.hold_list &&
                            <div className="col-lg-3 col-lg-4 col-md-6 col-12">
                                    <div className="widget-stat card user_info_wal">
                                      <div className="card-body p-4">
                                        <div className="media">
                                            <span className="mr-3">
                                            <i className="fas fa-shopping-basket text-org"></i>
                                            </span>
                                            <div className="media-body text-left">
                                                <h4 className="mb-0 text-dark">سبد خرید</h4>
                                                <hr/>
                                                <h5 className="d-block mb-4">  فعال  <span className="px-3 float-right text-light bg-org text-info rounded"> { this.state.hold_list.length }</span> </h5>
                                                <h5 className="d-block invisible"> - </h5>
                                          
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                          }




                        </div>
                    </div>

                    }

                    {/*  --------------------------------------------------------------------------*/
                /*                                Hold_tab                                     */
                /* --------------------------------------------------------------------------  */}

                    <div className="tab-pane fade" id="hold_tab">

                        <div className="row">
                            <div className="col-12 justify-content-center">
                                {this.state.hold_list ?
                                    <div className="">


                                        <div className="card-header">
                                            <div className="mb-2 w-100 justify-content hold_tabs_nav">

                                                <ul className="nav border-0 nav-tabs justify-content-center w-100" role="tablist_hold">
                                                    <li className="nav-item col-6">
                                                        <a className="w-100 mx-2 btn btn-outline-primary px-2 active" data-toggle="tab" href="#hold_tab_sub_1"> <i className="fas fa-shopping-basket"></i> لیست سبد های من</a>
                                                    </li>
                                                    <li className="nav-item col-6">
                                                        <a className="w-100 mx-2 btn btn-outline-primary px-2" data-toggle="tab" href="#hold_tab_sub_2"><i className="fas fa-chart-bar"></i> نمودار</a>
                                                    </li>


                                                </ul>

                                            </div>
                                        </div>

                                        <div className="card-body px-md-0 px-lg-4 px-0">

                                            <div className="tab-content">

                                                <div className="tab-pane fade show active" id="hold_tab_sub_1" role="tablist_hold">

                                                    <div className="row">
                                                        {this.state.total.total_hold_stus_1 &&
                                                            <div className="col-xl-4 col-lg-6 col-sm-12">
                                                                <div className="widget-stat card ">
                                                                    <div className="card-body p-4">
                                                                        <div className="media ai-icon">
                                                                            <span className="mr-3 ">

                                                                                <img src={`${Number(this.state.total.total_hold_stus_1.pnl) > 0 ? '/images/line_up.svg' : Number(this.state.total.total_hold_stus_1.pnl) < 0 ? '/images/line_down.svg' : ''}`} with="64" height="64" />

                                                                            </span>
                                                                            <div className="media-body">
                                                                           
                                                                                <h4 className={`mb-0 ltr ${Number(this.state.total.total_hold_stus_1.pnl) > 0 ? 'text-success' : Number(this.state.total.total_hold_stus_1.pnl) < 0 ? 'text-danger' : ''}`}>{`${Number(this.state.total.total_hold_stus_1.pnl) > 0 ? '+' : ''}`} $ {formatCurrency(Number(this.state.total.total_hold_stus_1.pnl), "USD", "en", true)} </h4>
                                                                                <span className={`badge text-white ${Number(this.state.total.total_hold_stus_1.roe) > 0 ? 'badge-success' : 'badge-danger'}`}>{Number(this.state.total.total_hold_stus_1.roe).toFixed(2)}%</span>

                                                                                <div className="d-block"> مجموع سود / ضرر - فعال</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {this.state.hold_performance &&


                                                            <>

                                                                {this.state.hold_performance.best !== undefined &&
                                                                    <div className="col-xl-4 col-lg-6 col-sm-12">
                                                                        <div className="widget-stat card ">
                                                                            <div className="card-body p-4">
                                                                                <div className="media ai-icon">
                                                                                    <span className="mr-3">
                                                                                        <img className="rounded-circle" src={`https://bitbaaz.com/img/coins/${this.state.hold_list[this.state.hold_performance.best].name}.png`} width="64" height="64" />
                                                                                    </span>
                                                                                    <div className="media-body">
                                                                                        <h5 className="mb-1">{this.state.hold_list[this.state.hold_performance.best].name}</h5>
                                                                                        <h4 className={`mb-0 ltr ${Number(this.state.hold_list[this.state.hold_performance.best].pnl) > 0 ? 'text-success' : Number(this.state.hold_list[this.state.hold_performance.best].pnl) < 0 ? 'text-danger' : ''}`}> {`${Number(this.state.hold_list[this.state.hold_performance.best].pnl) > 0 ? '+' : ''}`} $ {formatCurrency(Number(this.state.hold_list[this.state.hold_performance.best].pnl), "USD", "en", true)} </h4>
                                                                                        <span className={`badge text-white ${Number(this.state.hold_list[this.state.hold_performance.best].roe) > 0 ? 'badge-success' : 'badge-danger'}`}>{Number(this.state.hold_list[this.state.hold_performance.best].roe).toFixed(2)}%</span>

                                                                                        <div className="d-block"> بهترین عملکرد سبد</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }

                                                                {this.state.hold_performance.worst !== undefined && this.state.hold_performance.worst !== this.state.hold_performance.best &&
                                                                    <div className="col-xl-4 col-lg-6 col-sm-12">
                                                                        <div className="widget-stat card ">
                                                                            <div className="card-body p-4">
                                                                                <div className="media ai-icon">
                                                                                    <span className="mr-3">
                                                                                        <img className="rounded-circle" src={`https://bitbaaz.com/img/coins/${this.state.hold_list[this.state.hold_performance.worst].name}.png`} width="64" height="64" />
                                                                                    </span>
                                                                                    <div className="media-body">
                                                                                        <h5 className="mb-1">{this.state.hold_list[this.state.hold_performance.worst].name}</h5>
                                                                                        <h4 className={`mb-0 ltr ${Number(this.state.hold_list[this.state.hold_performance.worst].pnl) > 0 ? 'text-success' : Number(this.state.hold_list[this.state.hold_performance.worst].pnl) < 0 ? 'text-danger' : ''}`}> {`${Number(this.state.hold_list[this.state.hold_performance.worst].pnl) > 0 ? '+' : ''}`} $ {formatCurrency(Number(this.state.hold_list[this.state.hold_performance.worst].pnl), "USD", "en", true)} </h4>
                                                                                        <span className={`badge text-white ${Number(this.state.hold_list[this.state.hold_performance.worst].roe) > 0 ? 'badge-success' : 'badge-danger'}`}>{Number(this.state.hold_list[this.state.hold_performance.worst].roe).toFixed(2)}%</span>

                                                                                        <div className="d-block">
                                                                                            {
                                                                                                Number(this.state.hold_list[this.state.hold_performance.worst].pnl) > 0 ?
                                                                                                    'کم ترین عملکرد سبد'
                                                                                                    : 'بدترین عملکرد سبد'
                                                                                            }

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </>
                                                        }

                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">

                                                        <div className="card xs-card-table">
                                                        <div className="card-body">

                                                            <div className="table-responsive rounded">
                                                                <table className="table table-responsive-md">
                                                                <thead className="hidden-xs">
                                                                        <tr>
                                                                            <th>#</th>
                                                                            <th>مشخصات سبد</th>
                                                                            <th>مقدار</th>
                                                                            <th>مارجین</th>
                                                                            <th>ارزش فعلی </th>
                                                                            <th>قیمت مارکت</th>
                                                                            <th>سود/ضرر</th>
                                                                            <th>درصد سود</th>
                                                                            <th>عملیات</th>

                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>


                                                                        {this.state.hold_list.filter(hold_list => hold_list.stus == 1).map((item, index) => [

                                                                            <tr>
                                                                                <th className="hidden-xs">{index + 1}</th>
                                                                                <td className="border-xs-table"> <span className="xs_lab">نام ارز</span> {item.name} <img className='mr-2' src={`https://bitbaaz.com/img/coins/${item.name}.png`} width="35" height="35" /></td>
                                                                                <td> <span className="xs_lab"> مقدار</span> {item.size} <sub>({item.name})</sub> </td>
                                                                                <td> <span className="xs_lab">مارجین</span> {item.margin} </td>

                                                                                <td>
                                                                                <span className="xs_lab">ارزش فعلی</span>
                                                                                    $ {formatCurrency(Number(this.state.crypto_price[item.name].price * item.size), "USD", "en", true)}
                                                                                </td>

                                                                                <td>
                                                                                <span className="xs_lab">قیمت مارکت</span>
                                                                                    $ {formatCurrency(Number(this.state.crypto_price[item.name].price), "USD", "en", true)}</td>

                                                                                <td className={`ltr ${Number(item.pnl) > 0 ? 'text-success' : Number(item.pnl) < 0 ? 'text-danger' : ''}`} >
                                                                                   
                                                                                <span className="xs_lab">سود</span>
                                                                                   
                                                                                    $ {formatCurrency(Number(item.pnl), "USD", "en", true)}

                                                                                    <img src={`${Number(item.pnl) > 0 ? '/images/line_up.svg' : Number(item.pnl) < 0 ? '/images/line_down.svg' : ''}`} with="29" height="22" />

                                                                                </td>


                                                                                <td className={`ltr ${Number(item.roe) > 0 ? 'text-success' : Number(item.roe) < 0 ? 'text-danger' : ''}`} >
                                                                                    
                                                                                <span className="xs_lab">درصد سود</span>
                                                                                    {Number(item.roe).toFixed(2)}% </td>





                                                                                <th className='action'>

                                                                                <span className="xs_lab">عملیات</span>


                                                                                    <button onClick={this.open_hold_single_modal} data-hold_index={item.index} data-hold_trade_id={item.id} type="button" title="مشاهده" data-toggle="modal" data-target="#hold_single_modal">
                                                                                        <span className="xs_lab_action">جزیات</span>
                                                                                        <i className="far fa-external-link-alt"></i>
                                                                                     </button>


                                                                                </th>




                                                                            </tr>



                                                                        ])}
                                                                        {/* <tr>

                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>


                                                                            <td className='text-right font-weight-bold text-dark'>:جمع کل</td>
                                                                            <td className={` ltr ${Number(this.state.total.total_hold_stus_1.pnl) > 0 ? 'text-success' : 'text-danger'}`} >

                                                                                {`${Number(this.state.total.total_hold_stus_1.pnl) > 0 ? '+' : ''}`}


                                                                                $ {formatCurrency(Number(this.state.total.total_hold_stus_1.pnl), "USD", "en", true)}

                                                                            </td>
                                                                            <td className={`bg-dark-3 ltr ${Number(this.state.total.total_hold_stus_1.roe) > 0 ? 'text-success' : 'text-danger'}`} >
                                                                                {Number(this.state.total.total_hold_stus_1.roe).toFixed(2)}% </td>


                                                                        </tr> */}

                                                                    </tbody>
                                                                </table>
                                                            </div>



                                                            </div>
                                                        </div>

                                                        </div>


                                                    </div>





                                                    {this.state.hold_single_modal &&
                                                        <>

                                                            {/* -----------------hold_single_modal--------- */}

                                                            <div className="modal fade trade_modal" id="hold_single_modal">
                                                                <div className="modal-dialog modal-dialog-centered modal-dialog modal-lg" role="document">
                                                                    <div className="modal-content modal_bg_1">

                                                                        <div className="modal-body pb-0">

                                                                            <div className="row">

                                                                                <div className="col-12 col-sm-5 p-0">
                                                                                    <div className="row">
                                                                                        <div className="col-12">
                                                                                            <div className="widget-stat card">
                                                                                                <div className="card-body p-4">
                                                                                                    <div className="media ai-icon">
                                                                                                        <span className="mr-3 ">
                                                                                                            <img src={`https://bitbaaz.com/img/coins/${this.state.hold_single_modal.name}.png`} width="64" height="64" />
                                                                                                        </span>
                                                                                                        <div className="media-body text-right">
                                                                                                            <h5 className="mb-1">{this.state.crypto_price[this.state.hold_single_modal.name].name}</h5>
                                                                                                            <h4 className="mb-0 ltr text-black">$ {formatCurrency(Number(this.state.crypto_price[this.state.hold_single_modal.name].price * this.state.hold_single_modal.size), "USD", "en", true)}</h4>

                                                                                                            <div className="d-block text-primary ltr"> {this.state.hold_single_modal.size} <sub>( {this.state.hold_single_modal.name} )</sub> </div>

                                                                                                            {/* <span className="badge badge-rounded badge-outline-primary ltr float-right px-3">{this.state.hold_single_modal.size} {this.state.hold_single_modal.name}</span> */}


                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>


                                                                                        <div className="col-12">
                                                                                            <div className="widget-stat card">
                                                                                                <div className="card-body p-4">
                                                                                                    <div className="media ai-icon">

                                                                                                        <div className="media-body">
                                                                                                            <h5 className="d-block">مجموع سود / ضرر</h5>

                                                                                                            <h4 className={`mb-0 ltr ${Number(this.state.hold_single_modal.pnl) > 0 ? 'text-success' : 'text-danger'} `}>$  {formatCurrency(Number(this.state.hold_single_modal.pnl), "USD", "en", true)} </h4>
                                                                                                            <span className={`badge text-white ${Number(this.state.hold_single_modal.roe) > 0 ? 'badge-success' : 'badge-danger'} `}>{Number(this.state.hold_single_modal.roe).toFixed(2)}%</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col-12">
                                                                                            <div className="widget-stat card">
                                                                                                <div className="card-body p-4">
                                                                                                    <div className="media ai-icon">

                                                                                                        <div className="media-body">
                                                                                                            <h5 className="d-block">میانگین قیمت خرید</h5>

                                                                                                            <h4 className="mb-0 ltr text-black">$ {formatCurrency(Number(this.state.hold_single_modal.price_entry), "USD", "en", true)} </h4>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>



                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-12 col-sm-7 p-0">


                                                                                    <div className="col-12 pl-3 pl-xs-0">
                                                                                        <div className="card ">
                                                                                            <div className="card-header">
                                                                                                <h4 className="card-title text-dark"><i className="fas fa-exchange mr-2"></i> تراکنش ها </h4>
                                                                                            </div>
                                                                                            <div className="card-body p-0">
                                                                                                <div className="basic-list-group trnas_list">


                                                                                                    <Scrollbar style={{ width: 100 + '%', height: 334 + 'px' }}>
                                                                                                        <ul className="list-group p-0">

                                                                                                            {
                                                                                                                this.state.hold_single_trans && this.state.hold_single_modal.id === this.state.hold_single_trans[0].hold_trade_id ?
                                                                                                                    this.state.hold_single_trans.map((item, index) => [

                                                                                                                        <li className="list-group-item">

                                                                                                                            {item.type === 'B' ?
                                                                                                                                <i className="fas fa-arrow-circle-down text-success"></i>
                                                                                                                                : <i className="fas fa-arrow-circle-up text-danger"></i>
                                                                                                                            }

                                                                                                                            <div className="float-left ltr">

                                                                                                                                <span>
                                                                                                                                    {item.type === 'B' ?
                                                                                                                                        "خرید"
                                                                                                                                        : "فروش"
                                                                                                                                    }
                                                                                                                                </span>

                                                                                                                                {item.created_at}
                                                                                                                            </div>

                                                                                                                            <div className="float-right text-right">

                                                                                                                                <span>{item.margin}</span>
                                                                                                                                <div className="text-primary ltr"> {item.size} <sub>( {item.name} )</sub> </div>

                                                                                                                            </div>

                                                                                                                        </li>
                                                                                                                    ])
                                                                                                                    :
                                                                                                                    <div className="d-block mx-auto mt-4">
                                                                                                                        <ReactLoading type={'bars'} color={'#3d3d3d'} />
                                                                                                                    </div>

                                                                                                            }

                                                                                                        </ul>
                                                                                                    </Scrollbar>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>


                                                                                </div>




                                                                            </div>
                                                                        </div>
                                                                        <div className="modal-footer">
                                                                            <button type="button" id="add_to_trade_cancle" className="btn mr-auto btn-danger light" data-dismiss="modal">انصراف</button>
                                                                            <button type="button" className="btn btn-outline-primary px-5" data-toggle="modal" data-target="#add_trans_modal" > افزودن تراکنش  <i className="fas fa-plus-hexagon ml-1"></i></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            {/* -----------------add_trans_modal--------- */}
                                                            {this.state.hold_single_modal &&
                                                                <div className="modal fade trade_modal" id="add_trans_modal">
                                                                    <div className="modal-dialog modal-dialog-centered modal-dialog" role="document">
                                                                        <div className="modal-content modal_bg_1">
                                                                            <div className="modal-header">
                                                                                <h5 className="modal-title m-auto"><i className="far fa-exchange mr-1"></i>افزودن تراکنش </h5>
                                                                                <button type="button" className="close" data-dismiss="modal"><span>&times;</span>
                                                                                </button>
                                                                            </div>
                                                                            <div className="modal-body">

                                                                                <div className="input-group mb-3 trnas_type">
                                                                                    <div className="btn-group w-100 p-3 rounded">
                                                                                        <button onClick={this.change_add_trans_type} data-val='B' type="button" className="btn btn-dark btn-success">خرید</button>
                                                                                        <button onClick={this.change_add_trans_type} data-val='S' className="btn btn-dark btn-danger">فروش</button>
                                                                                    </div>
                                                                                </div>

                                                                                <input type="hidden" id="add_hold_trans_type" defaultValue='' />

                                                                                <div className="input-group mb-3">
                                                                                    <div className="input-group-prepend">
                                                                                        <span className="input-group-text"> مقدار  </span>
                                                                                    </div>
                                                                                    <input type="number" id="add_hold_trans_size" className="form-control ltr font-18" autoComplete="off" />
                                                                                    <div className="input-group-prepend ">
                                                                                        <span className="input-group-text">  <span id="add_to_trade_size_key">{this.state.hold_single_modal.name}</span>  </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="input-group mb-3">
                                                                                    <div className="input-group-prepend">
                                                                                        <span className="input-group-text">قیمت</span>
                                                                                    </div>
                                                                                    <input type="number" id="add_hold_trans_price" className="form-control ltr font-18" autoComplete="off" />
                                                                                    <div className="input-group-prepend">
                                                                                        <span className="input-group-text"> $</span>
                                                                                    </div>
                                                                                </div>


                                                                                <div className="text-center" id="totall_size_box">

                                                                                    مقدار باقی مانده:

                                                                                    <span onClick={this.totall_size_select} className="all_size" data-val={this.state.hold_single_modal.size}>


                                                                                        {this.state.hold_single_modal.size} <sub>{this.state.hold_single_modal.name}</sub></span>


                                                                                </div>





                                                                            </div>
                                                                            <div className="modal-footer">
                                                                                <button type="button" id="add_to_trade_cancle" className="btn mr-auto btn-danger light" data-dismiss="modal">انصراف</button>
                                                                                <button type="button" id="add_to_trade_action" onClick={this.add_hold_trans} className="btn btn-primary px-5" > ثبت تراکنش <i className="far fa-save ml-1"></i></button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }


                                                        </>
                                                    }





                                                </div>



                                                <div className="tab-pane fade " id="hold_tab_sub_2">

                                                    <div className="row hold_chart_row">

                                                        <div className="col-md-6 col-12">
                                                            <div className="card">
                                                                <div className="card-header">
                                                                    <h4 className="card-title text-dark">نمودار سرمایه ها بر اساس دسته بندی سبد </h4>

                                                                </div>
                                                                <div className="card-body p-4 ">
                                                                    {this.state.hold_chart_1 &&
                                                                        // <C3Chart
                                                                        //     className="ltr w-100 text-center"
                                                                        //     data={this.state.hold_chart_1}
                                                                        //     size={size}
                                                                        //     tooltip={tooltip}


                                                                        // />



                                                                        <ReactECharts
                                                                        option={this.state.hold_chart_1}
                                                                        notMerge={true}
                                                                        lazyUpdate={true}
                                                                        theme={"theme_name"}
                                                                        className="chart_new"
                                                                        />


                                                                    }

                                                                    

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6 col-12">
                                                            <div className="card">
                                                                <div className="card-header">
                                                                    <h4 className="card-title text-dark">نمودار سرمایه ها بر اساس هر پروژه </h4>

                                                                </div>
                                                                <div className="card-body p-4 ">
                                                                    {this.state.hold_chart_2 &&
                                                                        // <C3Chart
                                                                        //     className="ltr w-100 text-center"
                                                                        //     data={this.state.hold_chart_2}
                                                                        //     size={size}
                                                                        //     tooltip={tooltip}




                                                                        // />
                                                                         <ReactECharts
                                                                        option={this.state.hold_chart_2}
                                                                        notMerge={true}
                                                                        lazyUpdate={true}
                                                                        theme={"theme_name"}
                                                                        className="chart_new"
                                                                        />
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="col-12 chart_3" >
                                                            <div className="card">
                                                                <div className="card-header">
                                                                    <h4 className="card-title text-dark">نمودار سود و ضرر های بسته شده پروژه </h4>

                                                                </div>
                                                                <div className="card-body p-4 ">
                                                                    {this.state.hold_chart_3 &&
                                                                        // <C3Chart
                                                                        //     className="ltr w-100"
                                                                        //     bar={bar}
                                                                        //     grid={grid}
                                                                        //     legend={legend}
                                                                        //     tooltip={tooltip}
                                                                        //     data={this.state.hold_chart_3}
                                                                        // />
                                                                        <ReactECharts
                                                                        option={this.state.hold_chart_3}
                                                                        notMerge={true}
                                                                        lazyUpdate={true}
                                                                        theme={"theme_name"}
                                                                        className="chart_new"
                                                                        />
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>



                                                    </div>

                                                </div>


                                            </div>

                                        </div>



                                    </div>

                                    : <div className="d-block mx-auto text-center">
                                        {/* <ReactLoading className='d-inline-block' type={'bars'} color={'#656565'} /> */}

                                        سبد خرید شما خالی میباشد.
                                    </div>

                                }

                            </div>

                        </div>
                    </div>

                    {/*  --------------------------------------------------------------------------*/
                /*                               Trade_tab                                     */
                /* --------------------------------------------------------------------------  */}
                    <div className="tab-pane fade" id="trade_tab">
                        <div className="pt-4">

                            <div className="col-lg-12 px-xs-0">
                                <div className="card xs-card-table">
                                    <div className="card-header border-xs-table">
                                        <h4 className="card-title"><i className="far fa-arrow-alt-to-left text-dark mr-1"></i> مدیریت معاملات من</h4>
                                        <ul className="nav nav-pills" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#trade_tab_sub_1" role="tab" aria-selected="false">
                                                    فعال
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#trade_tab_sub_2" role="tab" aria-selected="true">
                                                    تاریخچه
                                                </a>
                                            </li>

                                        </ul>
                                    </div>

                                    <div className="tab-content">

                                        <div className="tab-pane fade show active" id="trade_tab_sub_1" role="tabpanel">
                                            <div className="card-body">
                                                <div className="table-responsive rounded">
                                                    <table className="table table-responsive-md">
                                                        <thead className="hidden-xs">
                                                            <tr>
                                                                <th>#</th>
                                                                <th>مشخصات ارز</th>
                                                                <th>مقدار</th>
                                                                <th>مارجین <sub></sub></th>
                                                                <th>قیمت ورود</th>
                                                                <th>قیمت مارکت</th>
                                                                <th>سود/ضرر</th>
                                                                <th>درصد سود</th>
                                                                <th>عملیات</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>


                                                            {

                                                                this.state.trade_list &&
                                                                this.state.trade_list.filter(trade_list => trade_list.stus == 1).map((item, index) => [


                                                                    <tr>
                                                                        <th className="hidden-xs">{index + 1}</th>
                                                                        <td className="table-name_by border-xs-table"> <span className="xs_lab"> ارز</span> <span className={`type ${item.pos}`}>{item.pos}</span>  <span className="level">{item.level_count}x</span> {item.signal_name_by}/{item.signal_name_to}</td>
                                                                        <td> <span className="xs_lab"> مقدار</span> {item.size} <sub>({item.signal_name_by})</sub> </td>
                                                                        <td> <span className="xs_lab"> مارجین</span>{item.margin}</td>
                                                                        <td><span className="xs_lab"> قیمت ورود</span> {item.price_entry}</td>
                                                                        <td>
                                                                        <span className="xs_lab"> مارکت</span>
                                                                            {formatCurrency(Number(this.state.crypto_price[item.signal_name_by].price), "USD", "en", true)}</td>




                                                                        <td className={`ltr ${Number(item.pnl) > 0 ? 'text-success' : Number(item.pnl) < 0 ? 'text-danger' : ''}`} >
                                                                        <span className="xs_lab">سود</span>

                                                                            $ {formatCurrency(Number(item.pnl), "USD", "en", true)}

                                                                            <img src={`${Number(item.pnl) > 0 ? '/images/line_up.svg' : Number(item.pnl) < 0 ? '/images/line_down.svg' : ''}`} with="29" height="22" />

                                                                        </td>


                                                                        <td className={`ltr ${Number(item.roe) > 0 ? 'text-success' : Number(item.roe) < 0 ? 'text-danger' : ''}`} >
                                                                        <span className="xs_lab">درصد سود</span>
                                                                            {Number(item.roe).toFixed(2)}% </td>



                                                                        <th className='action'>
                                                                        <span className="xs_lab">عملیات</span>

                                                                            <button onClick={this.open_trade_edit_modal} data-signal_index={item.index} type="button" title="ویرایش" data-toggle="modal" data-target="#edit_trade_modal"><i className="far fa-edit"></i></button>
                                                                            <button onClick={this.trade_remove} data-signal_id={item.id} type="button" title="حذف"><i className="far fa-trash-alt"></i></button>
                                                                            <button onClick={this.open_trade_edit_modal} data-signal_index={item.index} type="button" title="بستن معامله" data-toggle="modal" data-target="#close_trade_modal"><i className="far fa-badge-check"></i></button>

                                                                        </th>




                                                                    </tr>

                                                                ])}


                                                            {this.state.total.total_trade_stus_1 &&
                                                                this.state.total.total_trade_stus_1.pnl !== 0 &&

                                                                <tr>

                                                                    <td className="hidden-xs"></td>
                                                                    <td className="hidden-xs"></td>
                                                                    <td className="hidden-xs"></td>
                                                                    <td className="hidden-xs"></td>
                                                                    <td className="hidden-xs"></td>
                                                          

                                                                    <td className='text-right text-center-xs font-weight-bold pt-4 pt-2-xs border-xs-table'>:جمع کل</td>
                                                                    <td className={`ltr pt-4 ${Number(this.state.total.total_trade_stus_1.pnl) > 0 ? 'text-success' : Number(this.state.total.total_trade_stus_1.pnl) < 0 ? 'text-danger' : ''}`} >
                                                                    <span className="xs_lab">سود</span>
                                                                        {`${Number(this.state.total.total_trade_stus_1.pnl) > 0 ? '+' : ''}`}


                                                                        $ {formatCurrency(Number(this.state.total.total_trade_stus_1.pnl), "USD", "en", true)}

                                                                    </td>
                                                                    <td className={`ltr pt-4 ${Number(this.state.total.total_trade_stus_1.roe) > 0 ? 'text-success' : Number(this.state.total.total_trade_stus_1.roe) < 0 ? 'text-danger' : ''}`} >
                                                                    <span className="xs_lab">درصد سود</span>
                                                                        {Number(this.state.total.total_trade_stus_1.roe).toFixed(2)}% </td>
                                                                        <td className="hidden-xs"></td>


                                                                </tr>
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>


                                            {this.state.trade_modal &&
                                                <div className="modal fade trade_modal" id="edit_trade_modal">
                                                    <div className="modal-dialog modal-dialog-centered modal-dialog " role="document">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title m-auto"><i className="far fa-user-chart mr-1"></i> ویرایش  سبد معامله  </h5>
                                                                <button type="button" className="close" data-dismiss="modal"><span>&times;</span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body">


                                                                <div className="input-group mb-3">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">   مقدار با لورج         <kbd className="ml-1 bg-dark ltr">{this.state.trade_modal.level_count}<small> X</small></kbd> </span>
                                                                    </div>
                                                                    <input type="number" id="add_to_trade_size" className="form-control ltr font-18" defaultValue={this.state.trade_modal.size} autoComplete="off" />
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">  <span id="add_to_trade_size_key">{this.state.trade_modal.signal_name_by}</span>  </span>
                                                                    </div>
                                                                </div>


                                                                <div className="input-group mb-3">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">قیمت ورود:</span>
                                                                    </div>
                                                                    <input type="number" id="add_to_trade_price" className="form-control ltr font-18" defaultValue={this.state.trade_modal.price_entry} autoComplete="off" />
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text"> {this.state.trade_modal.signal_name_to}</span>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" id="add_to_trade_cancle" className="btn btn-danger light mr-auto" data-dismiss="modal">انصراف</button>
                                                                <button type="button" id="add_to_trade_action" onClick={this.edit_to_trade} className="btn btn-primary px-5" > ذخیره  <i className="fas fa-save ml-1"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            {this.state.trade_modal &&
                                                <div className="modal fade trade_modal" id="close_trade_modal">
                                                    <div className="modal-dialog modal-dialog-centered modal-dialog" role="document">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title m-auto"><i className="far fa-user-chart mr-1"></i>خروج از سبد معامله  </h5>
                                                                <button type="button" className="close" data-dismiss="modal"><span>&times;</span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body">





                                                                <div className="input-group mb-3">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">قیمت خروج:</span>
                                                                    </div>
                                                                    <input type="number" id="exit_to_trade_price" className="form-control ltr font-18" autoComplete="off" />
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">  {this.state.trade_modal.signal_name_to}</span>
                                                                    </div>
                                                                </div>



                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" id="add_to_trade_cancle" className="btn btn-danger light mr-auto" data-dismiss="modal">انصراف</button>
                                                                <button type="button" id="add_to_trade_action" onClick={this.exit_to_trade} className="btn btn-primary px-5" > بستن معامله  <i className="far fa-sack-dollar ml-1"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }





                                        </div>

                                        <div className="tab-pane fade" id="trade_tab_sub_2">

                                            <div className="card-body">
                                                <div className="table-responsive rounded">
                                                    <table className="table table-responsive-md ">
                                                    <thead className="hidden-xs">
                                                            <tr>
                                                                <th>#</th>
                                                                <th>نام ارز</th>
                                                                <th>مقدار</th>
                                                                <th>قیمت ورود</th>
                                                                <th>قیمت خروج</th>
                                                                <th>تاریخ ورود</th>
                                                                <th>تاریخ خروج</th>
                                                                <th>سود</th>
                                                                <th>درصد سود</th>


                                                            </tr>
                                                        </thead>
                                                        <tbody>


                                                            {
                                                                this.state.trade_list &&
                                                                this.state.trade_list.filter(trade_list => trade_list.stus == 3).map((item, index) => [



                                                                    <tr>
                                                                        <th className="hidden-xs">{index + 1}</th>
                                                                        <td className="table-name_by border-xs-table"><span className={`type ${item.pos}`}>{item.pos}</span>  <span className="level">{item.level_count}x</span> {item.signal_name_by}/{item.signal_name_to}</td>
                                                                        <td><span className="xs_lab"> مقدار</span>{item.size} <sub>({item.signal_name_by})</sub> </td>
                                                                        <td><span className="xs_lab"> قیمت ورود</span>{item.price_entry}</td>
                                                                        <td><span className="xs_lab"> قیمت خروج</span>{item.price_exit}</td>
                                                                        <td><span className="xs_lab"> تاریخ ورود</span>{item.created_at}</td>
                                                                        <td><span className="xs_lab"> تاریخ خروج</span>{item.closed_at}</td>



                                                                        <td className={`ltr ${Number(item.pnl) > 0 ? 'text-success' : Number(item.pnl) < 0 ? 'text-danger' : ''}`} >
                                                                        <span className="xs_lab">سود</span>
                                                                            $ {formatCurrency(Number(item.pnl), "USD", "en", true)}

                                                                            <img src={`${Number(item.pnl) > 0 ? '/images/line_up.svg' : Number(item.pnl) < 0 ? '/images/line_down.svg' : ''}`} with="29" height="22" />

                                                                        </td>


                                                                        <td className={`ltr ${Number(item.roe) > 0 ? 'text-success' : Number(item.roe) < 0 ? 'text-danger' : ''}`} >
                                                                           
                                                                        <span className="xs_lab">درصد سود</span>
                                                                            {Number(item.roe).toFixed(2)}% </td>


                                                                    </tr>



                                                                ])}

                                                            {this.state.total.total_trade_stus_3 &&
                                                                this.state.total.total_trade_stus_3.pnl !== 0 &&

                                                                <tr>

                                                                    <td className="hidden-xs"></td>
                                                                    <td className="hidden-xs"></td>
                                                                    <td className="hidden-xs"></td>
                                                                    <td className="hidden-xs"></td>
                                                                    <td className="hidden-xs"></td>

                                                                    <td className='text-right text-center-xs font-weight-bold pt-4 pt-2-xs border-xs-table'>:جمع کل</td>
                                                                    <td className={`ltr pt-4 ${Number(this.state.total.total_trade_stus_3.pnl) > 0 ? 'text-success' : Number(this.state.total.total_trade_stus_3.pnl) < 0 ? 'text-danger' : ''}`} >
                                                                    <span className="xs_lab">سود</span>
                                                                        {`${Number(this.state.total.total_trade_stus_3.pnl) > 0 ? '+' : ''}`}


                                                                        $ {formatCurrency(Number(this.state.total.total_trade_stus_3.pnl), "USD", "en", true)}

                                                                    </td>
                                                                    <td className={`ltr pt-4 ${Number(this.state.total.total_trade_stus_3.roe) > 0 ? 'text-success' : Number(this.state.total.total_trade_stus_3.roe) < 0 ? 'text-danger' : ''}`} >
                                                                    <span className="xs_lab">درصد سود</span>
                                                                        {Number(this.state.total.total_trade_stus_3.roe).toFixed(2)}% </td>


                                                                </tr>
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>


                                    </div>



                                </div>
                            </div>



                        </div>
                    </div>

                </div>





            </div>



        )
    }
}

