import React from 'react';
import * as H1 from '../Helper/H1';
import Send from '../Helper/Send';
import $ from "jquery";
import FileBase64 from 'react-file-base64';
import OneSignal from 'react-onesignal';



export default class Profile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            profile: null,
            avatar: localStorage.getItem('avatar'),
        }
    }

    componentDidMount() {

        console.log(OneSignal.User.PushSubscription.id);
        // OneSignal.User.PushSubscription.optOut();
        // OneSignal.User.PushSubscription.optIn();

        const self = this;
        let loader_1 = H1.notfy_loading();
        Send('user/profile', null).then(function (res) {

            const profile = res.data;
            self.setState({ profile });
            H1.notfy_dismiss(loader_1);

        });


        // OneSignal.Notifications.addEventListener('permissionChange', (event) => {
        //     event && this.change_signal(OneSignal.User.PushSubscription.id)
        // });

    }

    change_signal_live = (e) => {

        //console.log( OneSignal.User.PushSubscription.token);


        if (e.target.checked) {
            OneSignal.Notifications.requestPermission();
            OneSignal.Slidedown.promptPush()

            OneSignal.login(OneSignal.User.PushSubscription.token);
        }

        else {
            OneSignal.logout();
        }

        this.change_signal(OneSignal.User.PushSubscription.id)




    }



    /* -------------------------------------------------------------------------- */
    /* change_signal */
    /* -------------------------------------------------------------------------- */
    change_signal = (signal_id) => {

        let data = {
            signal_id: signal_id ?? OneSignal.User.PushSubscription.id,
            signal_stus: $("#signal_stus").is(":checked"),
        };

        H1.notfy_promise(Send('user/update/signal', data, false));
    }
    /* -
    
    
    /* -------------------------------------------------------------------------- */
    /* change_email */
    /* -------------------------------------------------------------------------- */
    change_email = (e) => {

        let data = {
            email: $("#email_in").val(),
            email_stus: $("#email_stus").is(":checked"),
        };


        !H1.isEmail(data.email) ? H1.alert_warning('ایمیل وارد شده صحیح نمی باشد.')
            :
            H1.notfy_promise(Send('user/update/email', data));
    }
    /* -------------------------------------------------------------------------- */
    /* change_telegram */
    /* -------------------------------------------------------------------------- */
    change_telegram = (e) => {

        let data = {
            telg_token: $("#telg_token").val(),
            telg_stus: $("#telg_stus").is(":checked"),
        };


        !data.telg_token ? H1.alert_warning('توکن وارد شده صحیح نمی باشد.')
            :

            H1.notfy_promise(Send('user/update/telegram', data));
    }
    /* -------------------------------------------------------------------------- */
    /* change_password */
    /* -------------------------------------------------------------------------- */
    change_password = (e) => {


        let data = {
            change_password_old: $("#change_password_old").val(),
            change_password_new: $("#change_password_new").val(),
        };


        !data.change_password_old ? H1.alert_warning('کلمه عبور فعلی را وارد نمایید.')
            : !data.change_password_new ? H1.alert_warning('کلمه عبور جدید را وارد نمایید.')
                : data.change_password_new.length < 8 ? H1.alert_warning('کلمه عبور باید حداقل 8 کارکتر باشد.') :
                    Send('user/update/password', data).then(function (res) {
                        if (res) {
                            H1.alert_confirm('success',
                                res.message, "لطفا دوباره وارد حساب خود شوید.").then(function (res2) {
                                    localStorage.clear();
                                    window.location.reload();
                                });
                        }
                    });
    } /* --------------------------------------------------------------------------
    */ /* getFiles */ /* -------------------------------------------------------------------------- */
    getFiles = (files) => {
        const self = this;

        var file_size = files["size"].replace(/\D/g, "");
        if (Number(file_size) > 300) {
            alert("حداکثر سایز مجاز فایل 300 کیلیوبایت میباشد.");
        }
        else {

            let data = {
                new_avatar: files.base64,
            };


            // Send('user/update/avatar', data).then(function (res) {

            // self.setState({ avatar: files.base64});
            // $('#panel_avatar').attr('src',files.base64);
            // localStorage.setItem('avatar', files.base64)
            // self.componentDidMount();

            // });


            H1.notfy_promise(Send('user/update/avatar', data)).then(function () {

                self.setState({ avatar: files.base64 });
                $('#panel_avatar').attr('src', files.base64);
                localStorage.setItem('avatar', files.base64)
                self.componentDidMount();

            });




        }


    }
    /* -------------------------------------------------------------------------- */
    /* open_input */
    /* -------------------------------------------------------------------------- */
    open_input = (e) => {
        $('#change_avatar_sec input').trigger('click');
    }
    /* -------------------------------------------------------------------------- */
    /* remove_avatar */
    /* -------------------------------------------------------------------------- */
    remove_avatar = (e) => {
        const self = this;
        H1.alert_verify('warning', "تصویر پروفایل حذف گردد؟").then(function (res2) {

            if (res2) {
                localStorage.setItem('avatar', '/images/avatar.svg')
                self.setState({ avatar: '/images/avatar.svg' });
                $('#panel_avatar').attr('src', '/images/avatar.svg');
                Send('user/update/avatar', null);
                self.componentDidMount();

                H1.notfy_success('تصویر پروفایل حذف شد.')



            }

        });



    }



    /* -------------------------------------------------------------------------- */
    /* update_app */
    /* -------------------------------------------------------------------------- */
    update_app = () => {
        window.location.reload(true);
    }

    render() {

        H1.page_name("پروفایل");

        $(document).ready(function () {
            $("#change_avatar_sec input").attr("accept", "image/png, image/jpeg");
        });

        return (


            <div className="container-fluid profile_page">


                {this.state.profile &&
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="profile card card-body px-3 pt-3 pb-0">
                                <div className="profile-head">
                                    <div className="photo-content">

                                    </div>
                                    <div className="profile-info mb-2">
                                        <div className="profile-photo position-relative">

                                            <img src={this.state.avatar} className="img-fluid rounded-circle border "
                                                alt="" />
                                            <div className="d-none" id="change_avatar_sec">
                                                <FileBase64 multiple={false} onDone={this.getFiles.bind(this)} />
                                            </div>

                                            {
                                                this.state.profile.avatar &&
                                                <button onClick={this.remove_avatar} type="button" className="remove_avatar"><i
                                                    className="far fa-trash-alt"></i></button>
                                            }

                                            <div onClick={this.open_input} className="upload_btn"> ویرایش <i
                                                className="far fa-camera-alt ml-1"></i> </div>

                                        </div>
                                        <div className="profile-details">


                                            <div className="profile-name px-3 pt-2">
                                                <h4 className="text-primary mb-0"> <b>{this.state.profile.name}
                                                    {this.state.profile.last_name}</b></h4>
                                                <p className="mb-0">{this.state.profile.number}</p>

                                            </div>

                                            <button type="button"
                                                className="btn btn-outline-primary align-self-center py-2 ml-auto"
                                                data-toggle="modal" data-target="#change_pass_modal"><i
                                                    className="far fa-lock-alt mr-1"></i> تغیر کلمه عبور </button>


                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


{/* 


                        <div className="col-lg-6 col-md-12">
                            <div className="card card-body">



                                <div className="form-group mb-0 ">
                                    <label><i className="far fa-bell text-warning mr-1"></i> دریافت اعلان‌‌ها (Push Notifications):
                                    </label>

                                    <div className="input-group">


                                        <div className="input-group-prepend">
                                            <span className="input-group-text">

                                                <label className="switch mx-2 mb-0">
                                                    <input type="checkbox" name="signal_stus" id="signal_stus"
                                                        defaultChecked={Number(this.state.profile.signal_stus)}
                                                        onChange={this.change_signal_live} />
                                                    <span className="slider round"></span>
                                                </label>
                                                فعال
                                            </span>


                                        </div>


                                    </div>
                                
              
                                </div>
                            </div>
                        </div> */}




                        <div className="col-lg-6 col-md-12">
                            <div className="card card-body">

                                <div className="form-group mb-0">
                                    <label><i className="fab fa-telegram text-info mr-1"></i> دریافت اعلان‌‌های اپلیکیشن در تلگرام:
                                        <small className="mt-3 ml-1 bg-light dark-bg-dark-2 d-inline-block p-1 rounded">برای دریافت
                                            کد فعالسازی به ربات تلگرام <a className="text-danger ltr d-inline-block"
                                                href="https://t.me/bitbaaz_bot" target="_blank">@bitbaaz_bot</a> مراجعه
                                            کنید.</small> </label>

                                    <div className="input-group">

                                        <input type="text" className="form-control py-md-4" autoComplete="off"
                                            placeholder="کد فعالسازی" name="telg_token" id="telg_token"
                                            defaultValue={this.state.profile.telg_token} />
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">

                                                <label className="switch mx-2 mb-0">
                                                    <input type="checkbox" name="telg_stus" id="telg_stus"
                                                        defaultChecked={Number(this.state.profile.notfy_telg)} />
                                                    <span className="slider round"></span>
                                                </label>
                                                فعال
                                            </span>

                                        </div>

                                    </div>


                                    <button className="btn btn-primary mx-auto d-block mt-3 px-5" onClick={this.change_telegram}
                                        type="button">ذخیره</button>
                                </div>



                            </div>

                        </div>





                        <div className="col-lg-6 col-md-12">
                            <div className="card card-body">






                                <div className="form-group mb-0">
                                    <label><i className="far fa-at text-warning mr-1"></i> دریافت اعلان‌‌های اپلیکیشن در ایمیل:
                                    </label>

                                    <div className="input-group">

                                        <input type="email" className="form-control py-md-4" autoComplete="off"
                                            placeholder="ایمیل" id="email_in" defaultValue={this.state.profile.email} />

                                        <div className="input-group-prepend">
                                            <span className="input-group-text">

                                                <label className="switch mx-2 mb-0">
                                                    <input type="checkbox" name="email_stus" id="email_stus"
                                                        defaultChecked={Number(this.state.profile.notfy_email)} />
                                                    <span className="slider round"></span>
                                                </label>
                                                فعال
                                            </span>

                                        </div>


                                    </div>

                                    <button className="btn btn-primary mx-auto d-block mt-3 px-5" onClick={this.change_email}
                                        type="button">ذخیره</button>
                                </div>



                            </div>

                        </div>








                        <div className="modal fade" id="change_pass_modal">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title"><i className="far fa-lock-alt mr-1"></i> تغیر کلمه عبور </h5>
                                        <button type="button" className="close" data-dismiss="modal"><span>&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">


                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">کلمه عبور فعلی:</span>
                                            </div>
                                            <input type="password" id="change_password_old" className="form-control" />
                                        </div>

                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">کلمه عبور جدید:</span>
                                            </div>
                                            <input type="password" id="change_password_new" className="form-control" />
                                        </div>




                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-danger light mr-auto"
                                            data-dismiss="modal">انصراف</button>
                                        <button type="button" onClick={this.change_password}
                                            className="btn btn-primary px-5">ذخیره <i className="fas fa-save ml-1"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>




                        {/* <button type="button" onClick={this.update_app} className="btn btn-info mx-auto px-4 mb-2">بروزرسانی
                اپلیکیشن <i className="fas sync-alt ml-1"></i></button> */}


                    </div>
                }


            </div>



        )
    }
}
