import React from 'react';
import * as H1 from '../Helper/H1';
import $ from "jquery";
import { formatCurrency } from "@coingecko/cryptoformat";
import Send from '../Helper/Send';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { Autoplay, Pagination, Navigation } from 'swiper';

import ReactECharts from 'echarts-for-react';







export default class Dashboard extends React.Component {



    constructor(props) {
        super(props);

        this.state = {
            coins: null,
            stocks: null,
            signal_info: null,
            signal_data_s3: null,
            signal_forex_data_s4: null,
            crypto_price: null,
            forex_price: null,
            invited: null,
            signal_s4_cahrt: null,
            signal_s4_cahrt_2: null,



        }
    }

    componentDidMount() {

        const self = this;
        let loader_1 = H1.notfy_loading();
        Send('page/dashboard', null).then(function (res) {
            if (res) {
                self.setState({ crypto_price: res.data.crypto_price });
                self.setState({ forex_price: res.data.forex_price });
                self.setState({ coins: res.data.coins });
                self.setState({ signal_info: res.data.signal_info });
                self.setState({ invited: res.data.invited });
                self.setState({ signal_s4_cahrt_2: res.data.signal_data_s3_chart_arr });
                self.calc(res.data.signal_data_s3);
                self.calc_2(res.data.signal_forex_data_s4);
                H1.notfy_dismiss(loader_1);
            }
        });

    }

    /* -------------------------------------------------------------------------- */
    /*                              Calc                                          */
    /* -------------------------------------------------------------------------- */



    /* -------------------------------------------------------------------------- */
    /*                              Calc                                          */
    /* -------------------------------------------------------------------------- */
    calc_2 = (data) => {
        data.forEach((item, index) => {
            data[index].index = index;
            data[index].pnl = H1.PNL2(item.price_exit , item.price_entry, item.size, item.pos);
            data[index].roe = H1.ROE2(item.pnl, item.size);
        });
        this.setState({ signal_forex_data_s4: data });

    }




    calc = (data) => {
        if (data.length) {

            let data_chart_label = [];
            let data_chart_value = [];

            data.forEach((item, index) => {
                data[index].index = index;
                data[index].pnl = H1.PNL(item.price_exit, item.price_entry, item.size, item.pos);
                data[index].roe = H1.ROE(item.pnl, item.margin);


                if (item.pnl < 0) {
                    data_chart_value.push(
                        {
                            value: Number(item.pnl).toFixed(2),
                            itemStyle: {
                                color: '#f44336'
                            }
                        }

                    );

                }
                else {
                    data_chart_value.push(Number(item.pnl).toFixed(2));
                }
                data_chart_label.push(item.created_at);
            });



            this.setState({ signal_data_s3: data });


            let chart_val = this.state.signal_s4_cahrt_2.pnl;

            chart_val.forEach((item, index) => {

                if (item < 0) {

                    chart_val[index] = {
                        value: item,
                        itemStyle: {
                            color: '#f44336'
                        }
                    }
                }

            });



            let the_cahrt_option_1 = {

                tooltip: {

                },
                animationDurationUpdate: 500,
                xAxis: {
                    data: this.state.signal_s4_cahrt_2.date,

                    axisLabel: {
                        fontFamily: 'Yekan_Bakh_FaNum'
                    }
                },
                yAxis: {
                    axisLabel: {
                        formatter: '{value} $',
                        align: 'right',
                        fontFamily: 'Yekan_Bakh_FaNum',
                    }
                },
                dataZoom: [
                    {
                        type: 'slider',
                        xAxisIndex: 0,
                        filterMode: 'none',
                        brushSelect: false
                    },

                ],
                series: [
                    {
                        name: 'PNL',
                        type: 'bar',
                        color: [
                            '#4caf50'
                        ],
                        data: chart_val
                    }
                ]
            };


            this.setState({ signal_s4_cahrt: the_cahrt_option_1 });

            // let data_chart_value_2 = [];


            // this.state.hold_list.forEach((item, index) => {

            //     let c_p = this.state.crypto_price[item.name].price;


            //      if(  item.zone_buy < c_p  < item.zone_sell ){

            //         data_chart_value_2.push({
            //                 value: 1,
            //                 name: item.name,
            //                 itemStyle: {
            //                   color: '#2196f3'
            //                 }
            //               }

            //             );
            //     }
            //     else if(  item.zone_buy <=  c_p ){
            //         data_chart_value_2.push(
            //             {
            //                 value: 1,
            //                 name: item.name,
            //                 itemStyle: {
            //                   color: '#f44336'
            //                 }
            //               }

            //             );

            //     }


            //     else if(  item.zone_sell  >=  c_p ){

            //         data_chart_value_2.push({
            //             value: 1,
            //             name: item.name,
            //             itemStyle: {
            //               color: '#4caf50 '
            //             }
            //           }

            //         );

            //     }



            // });



            // let option_cahrt_2 = {
            //     legend: {
            //         data: ['nodeA', 'nodeB']
            //       },
            //     series: [
            //       {
            //         type: 'treemap',
            //         data: data_chart_value_2
            //       }
            //     ]

            //   };



            // this.setState({ hold_chart_1: option_cahrt_2 });



        }


    }




    render() {

        H1.page_name("خانه");



        return (



            <div className="container-fluid dashboard_page">




                {/* <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center ">
                    <h2 className="font-w600 mb-2 mr-auto">داشبورد</h2>

                    <a href="javascript:void(0);" className="btn btn-secondary text-white mb-2"><i className="las la-calendar scale5 mr-3"></i>Filter Periode</a> 
                </div> */}


                <div id="banner" className="row">
                    <div className="col-12 mb-md-5">

                        <div className="d-none d-sm-block">
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={1}
                                loop
                                grabCursor
                                autoplay={{ delay: 2500 }}
                                pagination={{ clickable: true, pauseOnMouseEnter: false, disableOnInteraction: false }}
                                modules={[Autoplay, Pagination]}
                                className={'rounded'}
                            >



                                <SwiperSlide>
                                    <img className="d-block w-100" src="./images/ban-1.png" alt="Second slide" />
                                </SwiperSlide>

                                <SwiperSlide>
                                    <img className="d-block w-100" src="./images/ban-2.png" alt="Second slide" />
                                </SwiperSlide>


                                <SwiperSlide>
                                    <img className="d-block w-100" src="./images/ban-3.png" alt="Second slide" />
                                </SwiperSlide>

                            </Swiper>
                        </div>




                        <div className="d-block d-sm-none">


                            <Swiper
                                slidesPerView={1}
                                spaceBetween={1}
                                loop
                                grabCursor
                                autoplay={{ delay: 2500 }}
                                className={'rounded'}
                                pagination={{ clickable: false, pauseOnMouseEnter: false, disableOnInteraction: false }}
                                modules={[Autoplay, Pagination]}
                            >



                                <SwiperSlide>
                                    <img className="d-block w-100" src="./images/ban-m-1.jpg" alt="Second slide" />
                                </SwiperSlide>

                                <SwiperSlide>
                                    <img className="d-block w-100" src="./images/ban-m-2.jpg" alt="Second slide" />
                                </SwiperSlide>


                                {/* <SwiperSlide>
                                    <img className="d-block w-100" src="./images/ban-m-1.png" alt="Second slide" />
                                </SwiperSlide> */}

                            </Swiper>

                        </div>

                    </div>
                </div>



                {this.state.coins && this.state.crypto_price &&
                    <div id="coins" className="row px-md-3 px-0">

                        <Swiper
                            slidesPerView={'auto'}
                            spaceBetween={12}
                            grabCursor
                            className={'py-3 mt-2'}
                            freemode="true"
                        >

                            {this.state.coins.map((item, index) => [

                                <SwiperSlide>
                                    <div className="price_box m-t35">
                                        <div className="card card-coin">
                                            <div className="card-body text-center">

                                                <img className="mb-3 currency-icon bg-white" src={item.logo} width="64" height="64" />
                                                <h4>{item.symbol}</h4>

                                                <h2 className="text-black mb-2 font-w600">$
                                                    {formatCurrency(Number(item.price), "USD", "en", true)}
                                                </h2>



                                                {item.percent_change_7d < 0 ?
                                                    <p className="mb-0 fs-14">
                                                        <img src="/images/line_down.svg" with="29" height="22" />

                                                        {item.percent_change_7d &&
                                                            <>
                                                                <span className="text-danger ml-0 ml-md-2 pr-1">% <b> {Math.abs(Number(item.percent_change_7d).toFixed(1))}  </b></span>این هفته
                                                            </>

                                                        }


                                                    </p>

                                                    :

                                                    <p className="mb-0 fs-14">
                                                        {item.percent_change_7d &&
                                                            <>
                                                                <img src="/images/line_up.svg" with="29" height="22" />

                                                                <span className="text-success ml-0 ml-md-2 pr-1"> %<b> {Number(item.percent_change_7d).toFixed(1)}  </b></span>این هفته
                                                            </>
                                                        }
                                                    </p>

                                                }

                                            </div>
                                        </div>
                                    </div>

                                </SwiperSlide>

                            ])}

                        </Swiper>

                    </div>
                }



                {this.state.signal_info &&

                    <div id="signal_info" className="row mt-3">
                        <div className="col-lg-6 col-md-12 order-3 order-md-1">
                            <div className="widget-stat card" data-step="d3">
                                <div className="card-body p-4">
                                    <h4 className="card-title" onClick={this.run_step}> کل معاملات های بسته شده</h4>
                                    <hr />
                                    <h3>{this.state.signal_info.total_trades} سیگنال</h3>
                                    <div className="progress mb-2">
                                        <div className="progress-bar progress-animated bg-org" style={{ width: this.state.signal_info.total_trades_win + '%' }}></div>
                                    </div>
                                    <div>
                                        <b className="text-org"> {this.state.signal_info.total_trades_win}% </b>
                                        درصد بازدهی مثبت</div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-6 order-1 order-md-2" >
                            <div className="widget-stat card bg-warning text-center">
                                <div className="card-body p-md-4 p-3 text-black" data-step="d1">
                                    <h4 className="card-title text-black"> معاملات جدید</h4>
                                    <hr />
                                    <h3 className="text-black text-center mb-2 opacity-5"><i className="far fa-signal-stream"></i></h3>
                                    <h3 className="text-black text-center">{this.state.signal_info.signal_waiting} سیگنال</h3>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-6 order-2 order-md-3">
                            <div className="widget-stat card bg-info text-center">
                                <div className="card-body p-md-4 p-3 text-white" data-step="d2">
                                    <h4 className="card-title text-white">  معاملات فعال</h4>
                                    <hr />
                                    <h3 className="text-white text-center mb-2 opacity-5"><i className="far fa-signal-stream"></i></h3>
                                    <h3 className="text-white text-center">{this.state.signal_info.signal_open} سیگنال</h3>
                                </div>
                            </div>
                        </div>



                    </div>

                }








                {this.state.signal_forex_data_s4 &&
                
                    <div id="coins" className="row px-md-3 px-0 coins_s2 bg-light rounded" >
                    <h3 className="d-block  w-100 text-center mt-4"> معاملات  فارکس</h3>
                    

                         
                        <Swiper
                            slidesPerView={'auto'}
                            spaceBetween={18}
                            grabCursor
                            className={'py-3 mt-2'}
                            freemode="true"
                        >

                            {this.state.signal_forex_data_s4.map((item, index) => [

                                <SwiperSlide>
                                    <div className="price_box m-t35">
                                        <div className="card card-coin">
                                            <div className="card-body px-2 py-2 text-center">
                                                <div className="sig_id">
                                                    سیگنال: {this.state.signal_forex_data_s4.length - index}
                                                </div>

                                                <h3 className={`ltr mt-3 ${Number(item.pnl) > 0 ? 'text-success' : Number(item.pnl) < 0 ? 'text-danger' : ''}`}>


                                                    <img src={`${Number(item.pnl) > 0 ? '/images/line_up.svg' : Number(item.pnl) < 0 ? '/images/line_down.svg' : ''}`} with="29" height="22" />

                                                    {/* $ {formatCurrency(Number(item.pnl), "USD", "en", true)} */}

                                                    {Number(item.pnl) > 0 ? '+' : ''}

                                                    {Number(item.roe).toFixed(2)}%

                                                </h3>



                                                <h4 className="p-2">   {item.name_by}/{item.name_to}  - 

                                                    <b className={`mr-1 ${item.pos === "B" ? 'text-success' : 'text-danger'}`}>
                                                        {item.pos === "S" ? 'SELL' : item.pos === "B" ? 'BUY' : ''}
                                                    </b>
                                                    <hr className="my-1" />

                                                    <small>
                                                        ورود:   {item.price_entry}$ <span className="space-v"></span>
                                                        خروج:  {item.price_exit}$
                                                    </small>

                                                </h4>

                                                {/* <h2 className="text-black mb-2 font-w600">
                                                <span className={`type ${item.pos === "S" ? 'text-danger' : item.pos === "B" ? 'text-success':'' }`}>{item.pos === "S" ? 'SELL' : item.pos === "B" ? 'BUY':'' }</span>
                                                </h2> */}






                                            </div>
                                        </div>
                                    </div>

                                </SwiperSlide>

                            ])}

                        </Swiper>

                    </div>
                }

















                {this.state.signal_data_s3 &&
                    <div id="coins" className="row px-md-3 px-0 coins_s2" data-step="d6">
                    <h3 className="d-block  w-100 text-center mt-4"> معاملات  کریپتو</h3>

                        <Swiper
                            slidesPerView={'auto'}
                            spaceBetween={18}
                            grabCursor
                            className={'py-3 mt-2'}
                            freemode="true"
                        >

                            {this.state.signal_data_s3.map((item, index) => [

                                <SwiperSlide>
                                    <div className="price_box m-t35">
                                        <div className="card card-coin">
                                            <div className="card-body px-2 py-2 text-center">
                                                <div className="sig_id">
                                                    سیگنال: {this.state.signal_data_s3.length - index}
                                                </div>

                                                <h3 className={`ltr mt-3 ${Number(item.pnl) > 0 ? 'text-success' : Number(item.pnl) < 0 ? 'text-danger' : ''}`}>


                                                    <img src={`${Number(item.pnl) > 0 ? '/images/line_up.svg' : Number(item.pnl) < 0 ? '/images/line_down.svg' : ''}`} with="29" height="22" />

                                                    {/* $ {formatCurrency(Number(item.pnl), "USD", "en", true)} */}

                                                    {Number(item.pnl) > 0 ? '+' : ''}

                                                    {Number(item.roe).toFixed(2)}%

                                                </h3>



                                                <h4 className="p-2">   {item.name_by}/{item.name_to}   <kbd className="level py-0 s2">{item.level_count}x</kbd>

                                                    <b className={`mr-1 ${item.pos === "B" ? 'text-success' : 'text-danger'}`}>
                                                        {item.pos === "S" ? 'SELL' : item.pos === "B" ? 'BUY' : ''}
                                                    </b>
                                                    <hr className="my-1" />

                                                    <small>
                                                        ورود:   {item.price_entry}$ <span className="space-v"></span>
                                                        خروج:  {item.price_exit}$
                                                    </small>

                                                </h4>

                                                {/* <h2 className="text-black mb-2 font-w600">
                                                <span className={`type ${item.pos === "S" ? 'text-danger' : item.pos === "B" ? 'text-success':'' }`}>{item.pos === "S" ? 'SELL' : item.pos === "B" ? 'BUY':'' }</span>
                                                </h2> */}




                                                <p className="mb-2 fs-14">

                                                    <span className=" ml-0 ml-md-2 pr-1">% <b> {item.risk} </b></span> ریسک
                                                </p>






                                            </div>
                                        </div>
                                    </div>

                                </SwiperSlide>

                            ])}

                        </Swiper>

                    </div>
                }












                <div className="row">


                    {this.state.invited !== null &&

                        <div className="col-xl-6 col-lg-4 col-md-6 ">

                            <div className="widget-stat card bg-warning text-center">
                                <div className="card-body p-md-4 p-3 text-black" data-step="d1">
                                    <h4 className="card-title text-black"> کد دعوت دوستان </h4>
                                    <hr />



                                    <h3 className="text-black text-center mb-2 "> <i className="far fa-users opacity-5"></i>  {this.state.invited} نفر   </h3>
                                    <p className="text-black text-center my-4">اگر دوستان شما، با استفاده کد <kbd onClick={() => H1.Copy(localStorage.getItem('invite'))} className='font_fa letter_spacing cursor mx-1'> {localStorage.getItem('invite')}  <i className="far fa-clone opacity-5 "></i> </kbd>  و یا لینک زیر، در اپلیکییشن بیت باز ثبت نام نماییند. به ازای هر ثبت نام موفق <strong>5</strong> روز اشتراک رایگان به شما هدیه داده میشود..</p>
                                    <h4 className="text-black text-center mb-2"> <kbd className='font_fa invite_link_xs'> https://app.bitbaaz.com/?invite={localStorage.getItem('invite')}   </kbd>   <small className="cursor ml-2 opacity-5" onClick={() => H1.Copy('https://app.bitbaaz.com/?invite=' + localStorage.getItem('invite'))}><i className="far fa-clone"></i> <small> کپی </small></small> </h4>
                                </div>
                            </div>

                        </div>

                    }


                    {this.state.signal_info &&
                        <>
                            <div className="col-xl-3 col-lg-4 col-md-6 ">
                                <div className="card" data-step="d4">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item d-flex justify-content-between"><span className="mb-0">معاملات اسپات</span></li>
                                        <li className="list-group-item d-flex justify-content-between"><span className="mb-0">تعداد کل :</span><strong>{this.state.signal_info.total_trades_spot}</strong></li>
                                        <li className="list-group-item d-flex justify-content-between"><span className="mb-0">درصد بازدهی مثبت :</span><strong>{this.state.signal_info.total_trades_spot_win}%</strong></li>
                                        <li className="list-group-item d-flex justify-content-between"><span className="mb-0">مجموع سود :</span><strong>${this.state.signal_info.total_trades_spot_pnl}</strong></li>
                                        <li className="list-group-item d-flex justify-content-between"><span className="mb-0">میانگین بازدهی :</span><strong>%{((this.state.signal_info.total_trades_spot_pnl / this.state.signal_info.total_trades_spot) / 2).toFixed(2)}</strong></li>

                                    </ul>
                                </div>
                            </div>



                            <div className="col-xl-3 col-lg-4 col-md-6 ">
                                <div className="card" data-step="d5">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item d-flex justify-content-between"><span className="mb-0">معاملات فیوچر</span></li>
                                        <li className="list-group-item d-flex justify-content-between"><span className="mb-0">تعداد کل :</span><strong>{this.state.signal_info.total_trades_future}</strong></li>
                                        <li className="list-group-item d-flex justify-content-between"><span className="mb-0">درصد بازدهی مثبت :</span><strong>{this.state.signal_info.total_trades_future_win}%</strong></li>
                                        <li className="list-group-item d-flex justify-content-between"><span className="mb-0">مجموع سود :</span><strong>${this.state.signal_info.total_trades_future_pnl}</strong></li>
                                        <li className="list-group-item d-flex justify-content-between"><span className="mb-0">میانگین بازدهی :</span><strong>%{((this.state.signal_info.total_trades_future_pnl / this.state.signal_info.total_trades_future) / 2).toFixed(2)}</strong></li>

                                    </ul>
                                </div>
                            </div>
                        </>
                    }





                </div>











                {this.state.signal_s4_cahrt &&
                    <div className="card" data-step="d7">
                        <div className="card-body text-center">
                            <h4 className="card-title"> کل معاملات های بسته شده</h4>
                            <hr />
                            <div id="signal_s4_chart" className="row">

                                <ReactECharts
                                    option={this.state.signal_s4_cahrt}
                                    notMerge={true}
                                    lazyUpdate={true}
                                    theme={"theme_name"}
                                    className="signal_s4_chart"
                                />

                            </div>
                        </div>
                    </div>

                }






            </div>


        )
    }
}

