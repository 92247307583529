import $ from "jquery";
import Swal from 'sweetalert2' 
import jwt_decode from "jwt-decode";
import toast, { Toaster } from 'react-hot-toast';
import copy from 'copy-to-clipboard';


// import Push from 'push.js';
// import io from 'socket.io-client';




// socket_server.connect();

// socket_server.on("disconnect", () => {
//   console.log("Disconnected")
// });



// socket_server.on("connect", () => {
//   console.log(socket_server.connected); // true
// });



// socket_server.on("connect", () => {
//   console.log("socket is connected ! : ",socket_server.id);
// });

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 100,
})

let 
persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g],
arabicNumbers  = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];

//  export function Socket() {
//    return io("https://socket.app.bitbaaz.com");;
//  }



// export function Socket_Disconnect() {

//   return socket_server.disconnect();
// }


export function fixNumbers(str) {

      if(typeof str === 'string')
      {
        for(var i=0; i<10; i++)
        {
          str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
        }
      }
      return str;
}

export function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
export function loader_on() {
  $('body').addClass('loading');
  $('#preloader_2').show();
}
export function loader_off() {
  $('body').removeClass('loading');
  $('#preloader_2').hide();
}


export function Copy(text) {
  if(text){
    notfy_dismiss();
    notfy_success('کپی شد!')
    return copy(text);
  }

}


 export function notfy_promise(func,title = "ثبت ...") {


  return toast.promise(
    func,
     {
       loading:title,
       success: (data) => data.message,
       error: (data) => "خطا",

     }
   );


}


export function notfy_loading(title = "بارگذاری ...") {
  return toast.loading(title);
}

export function notfy_success(msg) {
  return toast.success(msg)
}

export function notfy_icon(msg,icon) {
  return toast(msg, {
    icon: icon,
  });
}


export function notfy_dismiss(id) {
  if(id){
     return toast.dismiss(id);
  }
  else{
     return toast.dismiss();
  }
}

export function notfy_remove(id) {
  if(id){
    return toast.remove(id);
    }
  else{
    return toast.remove();
  }
}



export function page_name(title) {
 // $('#page_name').slideUp('fast');
  $('#page_name_title').text(title);
 // $('#page_name').slideDown('fast');
//console.log(1);
  document.title = title;
}





export function alert_danger(msg) {
  return Swal.fire({
    icon: 'error',
    title: 'خطا ...',
    text: msg,
    confirmButtonText:'بستن',
  })
}

export function alert_warning(msg) {
  return Swal.fire({
    icon: 'warning',
    title: 'هشدار ...',
    text: msg,
    confirmButtonText:'بستن',
  })
}


export async function alert_confirm(type,title,msg,confirmButtonText ='متوجه شدم' ) {

 return await Swal.fire({
    icon: type,
    title: title,
    html: msg,
    confirmButtonText:confirmButtonText,
    allowOutsideClick: false,
    allowEscapeKey: false,
  }).then((result) => {
    if (result.isConfirmed) {
      return true
    }
  })

}



export async function demo_mod() {

  return await new Promise(function (resolve, reject) {

  if(localStorage.getItem('d_mod') == true){
    alert_confirm('warning', "محدودیت پلن !", "نسخه رایگان اپلیکیشن دارای محدودیت در استفاده میباشد. برای استفاده کامل از تمام امکانات، لطفا اکانت خود را ارتقا دهید.");
    reject(false);
  }
  else{
    resolve(true);
  }

})


}



export async function alert_verify(type,title,msg) {

  return await Swal.fire({
     icon: type,
     title: title,
     text: msg,
     confirmButtonText:'<i class="far fa-check-circle ml-2"></i> تایید ',
     allowOutsideClick: false,
     allowEscapeKey: false,
     showCancelButton: true,
     cancelButtonText: 'انصراف',
   }).then((result) => {
     if (result.isConfirmed) {
       return true
     }
     else{
      return false
     }
   })
 
 }


export async function alert_input(title,msg,valid) {
    const { value: is_valid  } = await Swal.fire({
    title: title,
    input: 'number',
    allowOutsideClick: false,
    inputLabel: msg,
    showCancelButton: true,
    confirmButtonText:'تایید',
    cancelButtonText:'انصراف',
    inputValidator: (value) => {
      
      if (!value || value != valid ) {
        return 'کد تایید اشتباه است.'
      }

    }

  })

  if (is_valid) {
    return true;
  }
  else{
    return false;
  }


}

export function isEmail(email) {
  var EmailRegex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return EmailRegex.test(email);
}

export function new_token(data) {

  var decoded = jwt_decode(data);
  
  localStorage.setItem('id', decoded.id)
  localStorage.setItem('name', decoded.name)
  localStorage.setItem('email', decoded.email)
  localStorage.setItem('sub', decoded.sub)
  localStorage.setItem('token', data)
  localStorage.setItem('exp', decoded.exp)
  localStorage.setItem('d_mod', decoded.d_mod)
  localStorage.setItem('invite', decoded.invite)

}


export function PNL(market_price,entry_price,size,pos) {
  let pnl = ( Number(market_price) -  Number(entry_price) ) * Number(size);
  if(pos === 'S'){
    pnl = pnl * -1;
  }
  return pnl;
}


export function ROE(pnl,margin) {

  if(pnl && margin){
    return ( pnl / Number(margin) ) * 100;
  }
  else{
    return 0;
  }
}






export function PNL2(market_price,entry_price,size,pos) {
  let pnl = ( Number(market_price) -  Number(entry_price) ) * Number(size * 100);
  if(pos === 'S'){
    pnl = pnl * -1;
  }
  return pnl;
}


export function ROE2(pnl,margin) {

  if(pnl && margin){
    return ( pnl / Number(margin * 10000) ) * 100;
  }
  else{
    return 0;
  }
}


export function INT(number) {

    return Number(number);
 
}

