import React from 'react';
import { NavLink } from 'react-router-dom';
import Send from '../Helper/Send';
import * as H1 from '../Helper/H1';
import $ from "jquery";
import ClearCache from 'react-clear-cache';

import { Steps, Hints } from 'intro.js-react';
import 'intro.js/introjs.css';



const steps = {

    '/': [
        {
            element: '[data-step="n0"]',
            intro: 'اطلاعات حساب و تمدید اشتراک',
        },
        {
            element: '[data-step="n1"]',
            intro: 'تغیر تم اپلیکیشن به حالت روز/شب',
        },
        {
            element: '[data-step="n2"]',
            intro: 'مشاهده اعلان ها',
        },
        {
            element: '[data-step="n3"]',
            intro: 'درخواست پشتیبانی',
        },
        {
            element: '[data-step="n4"]',
            intro: 'آموزش ها',
        },
                {
            element: '[data-step="n5"]',
            intro: 'صفحه اینستاگرام',
        },
        {
            element: '[data-step="d3"]',
            intro: 'تعداد کل سیگنال های تمام شده به همراه درصد موفقیت در معاملات',
        }, 
        {
            element: '[data-step="d1"]',
            intro: 'تعداد سیگنال در حال انتظار که به تازگی اضافه شدن و بصورت سفارش (order) ثبت شده اند.',
        },
        {
            element: '[data-step="d2"]',
            intro: 'تعداد سیگنال هایی و سفارش هایی که فعال شده اند و در حال حاضر فعال هستند.',
        },        {
            element: '[data-step="d4"]',
            intro: 'گزارش عملکرد معاملات اسپات',
        },        {
            element: '[data-step="d5"]',
            intro: 'گزارش عملکرد معاملات فیوچرز',
        },        {
            element: '[data-step="d6"]',
            intro: 'خلاصه آخرین سیگنال های بسته شده',
        },        {
            element: '[data-step="d7"]',
            intro: 'مودار میله ای سوددهی در طول زمان',
        },        {
            element: '[data-step="d8"]',
            intro: '',
        },

    ],

    '/signal': [
        {
            element: '[data-step="sf1"]',
            intro: 'معاملات اسپات: معاملاتی است که در هنگام ورود به معامله شما با پرداخت پول ارز مورد نظر را خریداری کرده و تحویل میگیرید. این معاملات تنها در جهت رشد قیمت سودآوری دارند.',
        },
        {
            element: '[data-step="sf2"]',
            intro: 'معاملات فیوچرز: اینگونه فیوچرز یا معاملات آتی همانطور که از اسمش پیداست مربوط به آینده است.(یعنی سررسید تحویل معامله در زمان آینده است). در این نوع معاملات به صورت دو طرفه هم از رشد قیمت هم از افت قیمت میتوان سودآوری کرد. برای استفاده از این معاملات حتما آموزش های لازم را مشاهده کنید.',
        },
        {
            element: '[data-step="sf3"]',
            intro: 'کم ریسک: حداکثر ضرر در صورت فعال شدن حد ضرر در آن ۱۵ درصد است',
        },
        {
            element: '[data-step="sf4"]',
            intro: 'ریسک متوسط: حداکثر ضرر در صورت فعال شدن حد ضرر در آن ۳۰ درصد است',
        },
        {
            element: '[data-step="sf5"]',
            intro: 'ریسک زیاد: حداکثر ضرر در صورت فعال شدن حد ضرر در آن ۴۵ درصد است',
        },
        {
            element: '[data-step="sf6"]',
            intro: 'اهداف پیش بینی شده هر سیگنال به ترتیب دسترسی',
        },
        {
            element: '[data-step="sb001"]',
            intro: 'جفت ارز مورد معامله و نوع معامله و اهرم معاملاتی',
        },
        {
            element: '[data-step="sb001_2"]',
            intro: 'حرف S مخفف Sell هست و به معنای معامله فروش، حرف B مخفف Buy هست و به معنای معامله خرید',
        },
        {
            element: '[data-step="sb002"]',
            intro: 'برای دریافت تمام هشدار ها و آخرین تغییرات سیگنال ابن گزینه را فعال کنید',
        },
        {
            element: '[data-step="sb003"]',
            intro: 'برای تست کردن سیگنال ها و یا ثبت معاملاتتان در خارج صرافی میتوانید از طریق این گزینه عملکرد سیگنال ها را ثبت نمایید. نا گفته نماند، از انجایی که شما هیچ مبلغی را بابت تست این معاملات پرداخت نمیکنید، سود ضرر های ثبت شده برایتان در کیف پول بیت باز تنها نشانه ای از عملکرد سیگنال ها است و قابل برداشت در نمیباشد.',
        },
        {
            element: '[data-step="sb1"]',
            intro: 'میزان سود و ضرر هر سیگنال فعال در لحظه به دلار',
        },
        {
            element: '[data-step="sb2"]',
            intro: 'درصد سود و ضرر هر سیگنال  فعال در لحظه',
        }, {
            element: '[data-step="sb3"]',
            intro: 'درصدی از مارجین که در صورت رسیدن سیگنال به حد ضرر (stop loss) از دست خواهید داد',
        }, {
            element: '[data-step="sb4"]',
            intro: 'قیمتی که معامله در آن فعال میشود(نقطه ورود) به دلار',
        }, {
            element: '[data-step="sb5"]',
            intro: 'مقدار ارزی که با توجه به مارجین و اهرم معاملاتی در نقطه ورود میتوان خرید',
        }, {
            element: '[data-step="sb6"]',
            intro: 'میزان پولی($) که به سیگنال اختصاص داده شده است',
        }, {
            element: '[data-step="sb7"]',
            intro: 'قیمتی لحظه ای ارز مورد نظر در بازار به دلار',
        }, {
            element: '[data-step="sb8"]',
            intro: 'نقطه ی فعال شدن حد ضرر و خروج از معامله با ضرر',
        }, {
            element: '[data-step="sb9"]',
            intro: 'نقطه فعال شدن حد سود پ خروج با سود از معامله به همراه درصد سودی که در صورت رسیدن به حد سود بدست خواهید آورد.',
        },
        {
            element: '[data-step="sb10"]',
            intro: 'وضعیت معامله به همراه زمان گذشته شده از فعال شدن معامله',
        },
    ],
    '/Hold': [
        {
            element: '[data-step="hb1"]',
            intro: 'رتبه داده شده به هر ارز در ضمینه خاص خودش در سایت مرجع messari ',
        },
        {
            element: '[data-step="hb2"]',
            intro: 'میزان تورم سالانه هر ارز ',
        },
        {
            element: '[data-step="hb3"]',
            intro: 'امتیازی که بیت باز به هر ارز در دسته بندی خاص خودش داده',
        },
        {
            element: '[data-step="hb4"]',
            intro: 'ماین کردن و یا استخراج، نوعی روش درآمدزایی در بعضی ارزهاست',
        },
        {
            element: '[data-step="hb5"]',
            intro: 'استیک کردن نوعی روش سودآوری مضاعف هنگام سرمایه گذاری بلند مدت است که در برخی ارز ها وجود دارد و به نوعی مانند سود سهام در بازار های سهامی است.',
        },
        {
            element: '[data-step="hb6"]',
            intro: 'میزان حداکثری پیشنهادی برای سرمایه گذاری در ارز معرفی شده است',
        },
        {
            element: '[data-step="hb7"]',
            intro: 'برای کسب اطلاعات بیشتر  و تکمیلی درباره پروژه و قیمت های پیشنهادی برای ورود و خروج سبد بلند مدت به این قسمت مراجعه کنید.',
        },
        {
            element: '[data-step="hb8"]',
            intro: 'برای دریافت هشدار خرید و فروش بلند مدت این گزینه را فعال کنید',
        },
        {
            element: '[data-step="hb9"]',
            intro: 'برای اضافه کردن ارز مورد نظر به سبد خرید بلند مدت و داشتن اطلاعات دقیق درباره میانگین قیمت خریدتان، میزان بازدهی هر ارز در سبد، ارزیابی عملکرد ارزها و … ، میتونید از این گزینه استفاده کنید',
        },

    ],

    '/Wallet': [
        {
            element: '[data-step="w1"]',
            intro: 'وضعیت کلی حساب شما شامل وضعیت سبد ها و معاملاتتان',
        },
        {
            element: '[data-step="w2"]',
            intro: 'گزارش عملکرد ارزهای موجود در سبد بلندمدت (Hold)',
        },
        {
            element: '[data-step="w3"]',
            intro: 'وضعیت معاملات فعال و یا شما به آن وارد شده اید.',
        },


    ],

    

};




const options = {
    nextLabel: 'بعدی',
    prevLabel: 'قبلی',
    doneLabel: 'پایان',

};


function do_exit(e) {
    localStorage.clear();
    window.location.reload(true);
}





export default class Header extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            notfy: null,
            theme: localStorage.getItem('theme'),
            steps_run: false,

        }
    }

    componentDidMount() {

        if (localStorage.getItem('sub') > 0) {
            this.notfy_data();
            var intervalId = setInterval(this.notfy_data, 40000);
            this.setState({ intervalId: intervalId });
        }


    }


    /* ------------------------------ get notfy_data  ------------------------------ */
    notfy_data = (e) => {
        const self = this;
        Send('page/notfy', null).then(function (res) {
            if (res) {
                self.setState({ notfy: res.data });
            }
        });
    }




    /* ------------------------------ read_notfy  ------------------------------ */
    read_notfy = (e) => {

        const self = this;
        let data = {
            notfy_id: e.currentTarget.dataset.notfy_id,
        };

        H1.notfy_promise(Send('page/notfy/read', data)).then(function () {
            self.notfy_data();
        });




    }



    /* ------------------------------ theme_switch  ------------------------------ */
    theme_switch = (e) => {

        if (this.state.theme === 'dark') {
            localStorage.setItem('theme', 'light')
            this.setState({ theme: 'light' });
            $('body').attr('data-theme-version', 'light');
        }
        else {
            localStorage.setItem('theme', 'dark')
            this.setState({ theme: 'dark' });
            $('body').attr('data-theme-version', 'dark');
        }


    }




    /* -------------------------------------------------------------------------- */
    /*                                update_app                                  */
    /* -------------------------------------------------------------------------- */
    update_app = () => {

        // emptyCacheStorage();
        //window.location.reload(true);

    }


    /* -------------------------------------------------------------------------- */
    /*                               step                                        */
    /* -------------------------------------------------------------------------- */

    run_step = () => {



        this.setState({ steps: steps[window.location.pathname] });

        this.setState({ steps_run: true });


    }


    stop_step = () => {

        this.setState({ steps_run: false });


    }


    render() {




        $(document).ready(function () {


            if ($(window).width() < 768) {
                $(".phone-tab-bar li a").click(function () {
                    window.scrollTo(0, 0);
                });
            }

        });








        return (

            <>
                {this.state.steps &&
                    <Steps
                        enabled={this.state.steps_run}
                        steps={this.state.steps}
                        initialStep={0}
                        onExit={this.stop_step}
                        options={options}
                    />
                }
                <div className="nav-header d-none d-sm-block">
                    <a className="brand-logo">
                        <img className="rounded" src="/images/bitbazz_dark.jpg" alt="بیت باز" width="54px" />
                    </a>

                    {/* <div className="nav-control d-none">
                        <div className="hamburger">
                            <span className="line"></span><span className="line"></span><span className="line"></span>
                        </div>
                    </div> */}
                </div>


                <div className="header">
                    <div className="header-content">
                        <nav className="navbar navbar-expand">
                            <div className="collapse navbar-collapse justify-content-between">

                                <div className="">
                                    <h2 id="page_name" className="font-w600 m-0"><i className="fas fa-level-down"></i> <span id="page_name_title">بیت باز</span> </h2>
                                </div>

                                <span className="text-muted cursor" onClick={this.run_step}><i className="far fa-info-circle"></i> راهنما </span>


                                <div className="header-left d-none">
                                    <div className="input-group search-area right d-lg-inline-flex d-none">
                                        <input type="text" className="form-control" placeholder="جستجو سریع..." />
                                        <div className="input-group-append">
                                            <span className="input-group-text"><a href="#"><i className="flaticon-381-search-2"></i></a></span>
                                        </div>
                                    </div>
                                </div>
                                <ul className="navbar-nav header-right main-notification">




                                    <li className="nav-item dropdown notification_dropdown hiiden_header_li" >
                                        <a className="nav-link ai-icon px-3" href="https://instagram.com/bit.baaz" target='_blank' data-step="n5">
                                            <i className="fab fa-instagram"></i>
                                        </a>
                                    </li>

                                    <li className="nav-item dropdown notification_dropdown hiiden_header_li" >
                                        <a className="nav-link ai-icon px-3" href="https://shop.bitbaaz.com" target='_blank' data-step="n4">
                                            <i className="far fa-book"></i>
                                        </a>
                                    </li>


                                    <li className="nav-item dropdown notification_dropdown hiiden_header_li" >
                                        <a className="nav-link ai-icon px-3" href="https://t.me/Bitbaaz_support" target='_blank' data-step="n3">
                                            <i className="far fa-headset"></i>
                                        </a>
                                    </li>




                                    <li className="nav-item dropdown notification_dropdown" >
                                        <a className="nav-link ai-icon px-3" href="#" role="button" data-toggle="dropdown" id="notfy_list" data-step="n2">
                                            <i className="far fa-bell"></i>
                                            {this.state.notfy && this.state.notfy.filter(notfy => notfy.read === false).length > 0 &&
                                                <span className="badge light text-white bg-primary rounded-circle">{this.state.notfy.filter(notfy => notfy.read === false).length}</span>
                                            }
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right">
                                            <div id="dlab_W_Notification1" className="widget-media dz-scroll p-3 height380">
                                                {this.state.notfy &&
                                                    <ul className="timeline">

                                                        {this.state.notfy.filter(notfy => notfy.read !== true).map((item, index) => [

                                                            <li>
                                                                <div className="timeline-panel">


                                                                    <div className={`media mr-2 ${item.read === false && item.type === 'alert' ? 'media-warning' : item.type === 'reward' ? 'media-success' : item.type === 'sub' ? 'media-info' : ''}`}>


                                                                        <i className="fas fa-alarm-exclamation"></i>
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <NavLink to="/Notification"><h6 className="mb-1">{item.subject}</h6></NavLink>
                                                                        <small className="d-block">{item.date_diff}</small>


                                                                        {
                                                                            item.read !== true &&
                                                                            <span className="read_btn" onClick={this.read_notfy} data-notfy_id={item.id} title="افزودن به خوانده شده"><i className="far fa-bookmark"></i> </span>
                                                                        }


                                                                    </div>
                                                                </div>
                                                            </li>

                                                        ])}

                                                    </ul>
                                                }
                                            </div>
                                            <NavLink to="/Notification" className="all-notification" >مشاهده تمام اعلانات <i className="ti-arrow-right"></i></NavLink>
                                        </div>
                                    </li>



                                    <li className="nav-item dropdown notification_dropdown" >
                                        <a className="nav-link ai-icon px-3" href="#" onClick={this.theme_switch} data-step="n1">

                                            {this.state.theme === 'dark' ?
                                                <i className="fas fa-moon"></i>
                                                : <i className="fas fa-sun"></i>
                                            }

                                        </a>
                                    </li>




                                    <li className="nav-item dropdown header-profile" >
                                        <a className="nav-link" href="#" role="button" data-toggle="dropdown" data-step="n0">
                                            <img id="panel_avatar" src={localStorage.getItem('avatar')} width="20" alt="" />
                                            <div className="header-info d-md-none d-lg-block">
                                                <span>{localStorage.getItem('name')}</span>
                                                <small> اشتراک مانده: <big> <b> {localStorage.getItem('sub_left')} </b> </big> روز</small>
                                            </div>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right">

                                            <div className="header-info d-md-block d-lg-none px-4">
                                                <span className="d-block">{localStorage.getItem('name')}</span>
                                                <small> اشتراک مانده: <big> <b> {localStorage.getItem('sub_left')} </b> </big> روز</small>
                                                <hr />
                                            </div>

                      


                                            <NavLink to="/profile" className="dropdown-item ai-icon">
                                                <i className="far fa-cog"></i>
                                                <span className="ml-2"> تنظیمات </span>
                                            </NavLink>

                                            <NavLink to="/plan" className="dropdown-item ai-icon">
                                                <i className="far fa-archive"></i>
                                                <span className="ml-2">خرید اشتراک </span>
                                            </NavLink>

                                            <a onClick={do_exit} className="dropdown-item ai-icon  text-danger">

                                                <i className="far fa-sign-out"></i>
                                                <span className="ml-2">خروج </span>
                                            </a>

                                            <ClearCache>
                                                {({ isLatestVersion, emptyCacheStorage }) => (
                                                    <div>
                                                        {!isLatestVersion && (
                                                            <>
                                                                <hr />
                                                                <a
                                                                    href="#"
                                                                    className="dropdown-item ai-icon text-info"
                                                                    onClick={e => {
                                                                        e.preventDefault();
                                                                        emptyCacheStorage();
                                                                    }}
                                                                >
                                                                    بروزرسانی اپلیکشن
                                                                </a>
                                                            </>
                                                        )}
                                                    </div>
                                                )}
                                            </ClearCache>


                                                                                       
                                            <div className="d-md-none">
                                                 <hr />

                                                <a href="https://wa.me/989129178732" className="dropdown-item ai-icon">
                                                <i className="far fa-headset"></i>
                                                    <span className="ml-2"> پشتیبانی </span>
                                                </a>
                                                <a href="https://bitbaaz.com/g7fx" className="dropdown-item ai-icon">
                                                <i className="far fa-book"></i>
                                                    <span className="ml-2"> آموزش </span>
                                                </a>

                                                <a href="https://instagram.com/bit.baaz" className="dropdown-item ai-icon">
                                                <i className="fab fa-instagram"></i>
                                                    <span className="ml-2"> اینستاگرام </span>
                                                </a>
                                            </div>

                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>




                <div className="phone-tab-bar d-block d-sm-none">

                    <nav className="mobile_b_bar">
                        <ul>
                            <li>

                                <NavLink aria-expanded="false" exact="true" to="/">
                                    <i className="far fa-home-alt"></i>
                                </NavLink>
                            </li>
                        </ul>

                        <ul>
                            <li>
                                <NavLink aria-expanded="false" exact="true" to="/News">
                                    <i className="far fa-newspaper"></i>
                                </NavLink>
                            </li>
                        </ul>

                        <ul>
                            <li>
                                <NavLink aria-expanded="false" exact="true" to="/signal">
                                    <i className="far fa-signal-stream"></i>
                                </NavLink>
                            </li>
                        </ul>


                        <ul>
                            <li>
                                <NavLink aria-expanded="false" exact="true" to="/Hold">
                                    <i className="far fa-shopping-basket"></i>
                                </NavLink>
                            </li>
                        </ul>

                        <ul>
                            <li>
                                <NavLink aria-expanded="false" exact="true" to="/Wallet">
                                    <i className="far fa-wallet"></i>
                                </NavLink>
                            </li>
                        </ul>






                    </nav>



                </div>


            </>

        )
    }
}

