import axios from 'axios';
import Swal from 'sweetalert2'
import * as H1 from '../Helper/H1';
//import packageJson from '../../package.json';

// let encoded = base64_encode('YOUR_DECODED_STRING');
// let decoded = base64_decode('WU9VUl9ERUNPREVEX1NUUklORw==');

// console.log(encoded,decoded);



export default async function Send(url,data,loader = null) {


loader && H1.notfy_loading(loader)
 

  return await axios.post('https://mg.bitbaaz.com/api/app/' + url , data, {
  //return await axios.post('http://localhost:8000/api/app/' + url , data, {

  headers: {
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
   // 'app_ver': packageJson.version,
   
  }})
  .then(function (response) {
  loader &&  H1.notfy_dismiss();
  



  // if(response.data.status === "error" && response.data.code == 204 ){
  
  //   return H1.alert_confirm('info',"بروزرسانی اپلیکیشن!", response.data.message).then(function (res) {
  //     window.location.reload(true);

  //  });

  //  }



     if(response.data.status === "error" && response.data.code == 403 ){


  
     return H1.alert_confirm('danger',"خطا در دستررسی", "لطفا مجددا وارد حساب خود شوید..").then(function (res) {
        localStorage.clear();
        window.location.reload();
    });

    }






    else if(response.data.status === "error" && response.data.code == 400 ){
  
      return H1.alert_confirm('warning',"اتمام اشتراک حساب!", "جهت استفاده از پنل، اشتراک حساب خود را تمدید نمایید.").then(function (res) {

        localStorage.setItem('sub', null);
        window.location.replace("/plan");
   

    });

    }



    else if(response.data.status === "error" && response.data.code == 401 ){
  
     H1.alert_confirm('warning', "محدودیت پلن !", "نسخه رایگان اپلیکیشن دارای محدودیت در استفاده میباشد. برای استفاده کامل از تمام امکانات، لطفا اکانت خود را ارتقا دهید.");
     return null;
     }
    


    else if(response.data.status === "error"){
      throw response.data.message;  
      
    }

    else{
      return response.data;
    }

    
  })
  .catch(function (error) {
    //console.log(error);


    H1.loader_off();
     Swal.fire({
      icon: 'error',
      title: 'خطا ...',
      text: error,
      confirmButtonText:'بستن',
    })

  
    return null;
  });

 

    //return response;
}