import React from 'react';
import * as H1 from '../Helper/H1';
import Send from '../Helper/Send';
import $ from "jquery";
import toast, { Toaster } from 'react-hot-toast';
import packageJson from '../../package.json';

const search = new URL(window.location).searchParams;
const invite_url = search.get("invite");

console.log(invite_url);

export default class Login extends React.Component {






    do_login = (e) => {

        H1.notfy_loading('ورود ...');
        $(e.target).attr('disabled', true);

        let data = {
            login_number: $("#login_number").val(),
            login_password: $("#login_password").val(),
        };





        Send('user/login', data).then(function (res) {

            if (res) {
                H1.new_token(res.data.token);
                res.data.avatar ? localStorage.setItem('avatar', res.data.avatar)
                    : localStorage.setItem('avatar', '/images/avatar.svg')

                window.location.reload();
            }
            else {
                H1.notfy_dismiss();
                $(e.target).attr('disabled', false);
            }
        });


    }


    sms_verfication = (phone,reg) => {

        return new Promise(function (resolve, reject) {


            let new_code = Math.floor((Math.random() * 100000) + 1);

            //console.log(new_code);


            H1.notfy_loading();

            Send('user/phone_valid', {
                phone: phone,
                reg: reg,
                code: (new_code * 3) + 17,

            }).then(function (res) {

                H1.notfy_dismiss();

                if (res) {
                    H1.alert_input("کد تایید را وارد نمایید.", "کد تایید به " + phone + " ارسال شد.", new_code).then(function (res2) {
                        resolve(res2);
                    });
                }

            }).catch(function (error) {

                reject(false);

            });


        });

    }





    do_register = (e) => {


        let data = {
            register_name: $("#register_name").val(),
            register_last_name: $("#register_last_name").val(),
            register_number: $("#register_number").val(),
            register_password: $("#register_password").val(),
            register_accept_privacy: $("#accept_privacy").is(":checked"),
            register_invite: $("#register_invite").val(),
            // register_email: $("#register_email").val(),
            

        };

        !data.register_name || !data.register_last_name ? H1.alert_warning('نام و نام خانوادگی لازم می باشد.')
            : data.register_number.length !== 11 || "09" != data.register_number.substr(0, 2) ? H1.alert_warning('شماره موبایل نا معتبر می باشد.')
                : data.register_password.length < 8 ? H1.alert_warning('کلمه عبور باید حداقل 8 کارکتر باشد.')
                    : data.register_accept_privacy !== true ? H1.alert_warning('لطفا شرایط و قوانین را مطالعه و تایید نماید.')


                        : this.sms_verfication(H1.fixNumbers(data.register_number),true).then(function (res) {


                            if (res === false) {
                                H1.alert_warning('شماره همراه تایید نشد!')
                            }

                            else {


                                Send('user/register', data).then(function (res) {

                                    H1.alert_confirm('success', "ثبت نام انجام شد!", "1 هفته اشتراک رایگان برای شما فعال شد.").then(function (res2) {


                                        H1.new_token(res.data);
                                        res.data.avatar ? localStorage.alert_confirm('avatar', res.data.avatar)
                                        : localStorage.setItem('avatar', '/images/avatar.svg')
                                        window.location.reload();

                                    });

                                });

                            }



                        });


    }

    /* -------------------------------------------------------------------------- */
    /*                                 Show password                              */
    /* -------------------------------------------------------------------------- */

    password_recovery = (e) => {


            let data = {
             recovery_number: $("#recovery_number").val(),
             };

            data.recovery_number.length !== 11 || "09" != data.recovery_number.substr(0, 2) ? H1.alert_warning('شماره موبایل نا معتبر می باشد.')
            : this.sms_verfication(H1.fixNumbers(data.recovery_number),false).then(function (res) {


                            if (res === false) {
                                H1.alert_warning('شماره همراه تایید نشد!')
                            }

                            else {


                                H1.notfy_promise( Send('user/recovery', data)).then(function () {

                         
                                    $('#password_recovery_modal').trigger('click')
         
                                });


                            }



                        });



    }

    /* -------------------------------------------------------------------------- */
    /*                                 Show password                              */
    /* -------------------------------------------------------------------------- */
    show_pass = (e) => {



        let pass_id = e.currentTarget.dataset.pass_id;
        let input_type = $(pass_id).attr('type');

        if (input_type === "password") {
            $(pass_id).attr('type', 'text')
            $(".show_pass").children("i").attr("class", "far fa-eye");
        }
        else {
            $(pass_id).attr('type', 'password');
            $(".show_pass").children("i").attr("class", "far fa-eye-slash");
        }



    }



    render() {

        $(document).ready(function () {

        });



        return (




            <div className="authincation h-100">

                <Toaster
                    position="top-center"
                    toastOptions={{
                        duration: 3000,
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        ariaProps: {
                            role: 'status',
                            'aria-live': 'polite',
                        },
                    }}
                />

                {/* <ul className="nav nav-tabs" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-toggle="tab" href="#login"><i className="la la-home mr-2"></i> login</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#register"><i className="la la-user mr-2"></i> register</a>
                                        </li>

                                    </ul> */}

                <div className="container h-100 login_page">
                    <div className="row justify-content-center h-100 align-items-center">
                        <div className="col-md-6">
                            <div className="authincation-content mt-5">
                                <div className="row no-gutters">
                                    <div className="col-12">
                                        <div className="auth-form p-0">


                                            <ul className="nav nav-pills mb-4 w-100 text-center border-bottom">
                                                <li className="nav-item w-50 bgl-light">
                                                    <a href="#login" className="nav-link rounded-0 py-3 active " data-toggle="tab" aria-expanded="false"><i className="far fa-sign-in mx-2"></i> ورود </a>
                                                </li>
                                                <li className="nav-item w-50 bgl-light">
                                                    <a href="#register" className="nav-link rounded-0 py-3" data-toggle="tab" aria-expanded="false"><i className="far fa-user-edit mx-2"></i> ثبت نام </a>
                                                </li>
                                            </ul>


                                            <div className="text-center mb-3">
                                                <img src="images/bitbazz_dark.jpg" alt="" width="60" />
                                            </div>

                                            <div className="tab-content login_page px-5 pb-4" role="tabpanel">

                                                <div id="login" role="tabpanel" className="tab-pane fade show active">
                                                    <div className="org">
                                                        <h4 className="text-center mb-4">پنل کاربری بیت باز</h4>
                                                        <hr />

                                                        <div className="form-group">
                                                            <label className="mb-1"><strong>شماره موبایل</strong> </label>
                                                            <input type="text" id="login_number" className="form-control in_c" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="mb-1"><strong>کلمه عبور</strong></label>
                                                            <input type="password" id="login_password" className="form-control in_c" />


                                                            <span className="show_pass" onClick={this.show_pass} data-pass_id="#login_password">
                                                                <i className="far fa-eye-slash"></i>
                                                            </span>

                                                        </div>

                                                        <br />

                                                        <div className="text-center">
                                                            <button type="submit" onClick={this.do_login} className="btn btn-primary btn-block">ورود به حساب کاربری</button>
                                                        </div>
                                                        <hr />
                                                        <div className="new-account mt-4" role="tablist">
                                                            <p className="mb-0">کلمه عبور را فراموش کرده اید!

                                                                <a href="#password_recovery_modal" data-toggle="modal" data-target="#password_recovery_modal" className="text-primary pill_btn"> بازیابی کلمه عبور </a>

                                                            </p>
                                                        </div>

                                                    </div>
                                                </div>



                                                <div id="register" role="tabpanel" className="tab-pane fade">
                                                    <h4 className="text-center mb-4">ثبت نام در بیت باز</h4>
                                                    <hr />

                                                    <div className="row">

                                                        <div className="form-group col-12 col-md-6">
                                                            <label className="mb-1"><strong>نام</strong></label>
                                                            <input type="text" id="register_name" className="form-control in_c" />
                                                        </div>

                                                        <div className="form-group col-12 col-md-6">
                                                            <label className="mb-1"><strong>نام خانوادگی</strong></label>
                                                            <input type="email" id="register_last_name" className="form-control in_c" />
                                                        </div>

                                                    </div>

                                                    <div className="form-group">
                                                        <label className="mb-1"><strong>شماره موبایل</strong></label>
                                                        <input type="text" id="register_number" className="form-control in_c" />
                                                    </div>

                                                    {/* <div className="form-group">
                                                        <label className="mb-1"><strong> ایمیل  <small>(اختیاری)</small> </strong></label>
                                                        <input type="email" id="register_email" className="form-control in_c" />
                                                    </div> */}



                                                    <div className="form-group">
                                                        <label className="mb-1"><strong>کلمه عبور <small>(حداقل 8 کارکتر)</small></strong></label>
                                                        <input type="password" id="register_password" className="form-control" />
                                                        <span className="show_pass" onClick={this.show_pass} data-pass_id="#register_password">
                                                            <i className="far fa-eye-slash"></i>
                                                        </span>
                                                    </div>


                                                    <div className="form-group">
                                                        <label className="mb-1"><strong> کد معرف  <small>(اختیاری)</small> </strong></label>
                                                        <input type="email" id="register_invite" className="form-control in_c" defaultValue={invite_url} />
                                                    </div>


                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="accept_privacy" />
                                                        <label className="form-check-label" htmlFor="accept_privacy">

                                                            تمامی  <a className="text-info" href="https://bitbaaz.com/terms-privacy" rel="help" target="_blank">شریط و قوانین</a> استفاده از اپلیکشین را خوانده ام و تمامی آن ها را می پذیرم.

                                                        </label>
                                                    </div>

                                                    <div className="text-center mt-4">
                                                        <button type="submit" onClick={this.do_register} className="btn btn-primary btn-block">تکمیل ثبت نام</button>
                                                    </div>





                                                </div>


                                            </div>
                                        </div>




                                        {/* ----------------------- password recovery ----------------- */}
                                        <div className="modal fade" id="password_recovery_modal">
                                            <div className="modal-dialog modal-dialog-centered modal-dialog " role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title m-auto"><i className="far fa-lock-alt mr-1"></i> بازیابی کلمه عبور </h5>
                                                        <button type="button" className="close" data-dismiss="modal"><span>&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">

                                                    <div className="form-group">
                                                            <label className="mb-1"><strong>شماره موبایل</strong> </label>
                                                            <input type="text" id="recovery_number" className="form-control in_c" />
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-danger light mr-auto" data-dismiss="modal">انصراف</button>
                                                        <button type="button" onClick={this.password_recovery} className="btn btn-primary px-5" > دریافت کد تایید </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>










                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        )
    }
}

