import React from 'react';
import * as H1 from '../Helper/H1';
import Send from '../Helper/Send';
import Push from 'push.js';
// import {io} from 'socket.io-client';
// const socket = io("https://socket.app.bitbaaz.com");

//  socket.on('chat message', function(msg) {
//      console.log(msg);
//      Push.create(msg)
//    });




//    Push.create("Hello world!", {
//      body: "How's it hangin'?",
//      icon: '/images/bitbazz_dark.jpg',
//      timeout: 9000,
//      onClick: function () {
//          window.focus();
//          this.close();
//      }
//  });



export default class Temp extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            packages: [],

        }
    }

    

    componentDidMount() {



 
    }


    
    test = (e) => {

       ///  socket.emit('chat message', "123452");

        
   
    }

render() {
    
    
    

    return (

<>
<button type="button" onClick={this.test}  className="btn btn-primary mx-2 p-2" > test </button>

</>


)}}

