import React from 'react';
import * as H1 from '../Helper/H1';
import Send from '../Helper/Send';
import $ from "jquery";
import { formatCurrency } from "@coingecko/cryptoformat";



export default class Signal extends React.Component {

    /* -------------------------------------------------------------------------- */
    /*                                 constructor                                */
    /* -------------------------------------------------------------------------- */

    constructor(props) {
        super(props);

        this.state = {
            signals: [],
            signal_2: [],
            crypto_price: null,
            forex_price: null,
            trade_modal: {},
            steps_run: false,
            tools_calc_risk: null,
            filter: {
                type_trade: 'S',
                type_risk: 'L',
                type_time: 'L',
            },
        }

        //this.Socket = H1.Socket();
    }


    /* -------------------------------------------------------------------------- */
    /*                              componentDidMount                             */
    /* -------------------------------------------------------------------------- */


    componentDidMount() {

        let loader_1 = H1.notfy_loading();
        const self = this;

        Send('page/signal', null).then(function (res) {
            if (res) {
                self.setState({
                    crypto_price: res.data.crypto_price,
                    forex_price: res.data.forex_price
                }, () => {
                    self.calc(res.data.signal);
                    self.calc_2(res.data.signal_2);
                    H1.notfy_dismiss(loader_1);
                });

            }
        });



        // this.Socket.on('context_price', function (the_res) {
        //     if (the_res) {
        //         H1.notfy_loading('بروزرسانی...');
        //         self.setState({ crypto_price: JSON.parse(the_res) });
        //     }


        // });

        // this.Socket.on('hi', function (the_res_2) {
        //     if (the_res_2) {
        //         console.log(the_res_2);
        //     }


        // });

    }

    /* -------------------------------------------------------------------------- */
    /*                              Calc                                          */
    /* -------------------------------------------------------------------------- */
    calc = (data) => {
        data.forEach((item, index) => {
            data[index].index = index;
            data[index].pnl = H1.PNL(this.state.crypto_price[item.name_by].price, item.price_entry, item.size, item.pos);
            data[index].roe = H1.ROE(item.pnl, item.margin);
            data[index].tp_p = (((item.take_profit - item.price_entry) / item.price_entry) * 100 * (item.level_count)).toFixed(1);
            // data[index].tp_p_2 = (((item.take_profit_2 - item.price_entry) / item.price_entry) * 100 * (item.level_count)).toFixed(1);
            // data[index].tp_p_3 = (((item.take_profit_3 - item.price_entry) / item.price_entry) * 100 * (item.level_count)).toFixed(1);
            if (item.tp_p < 0) {
                data[index].tp_p = item.tp_p * -1;
            }


        });
        this.setState({ signals: data });

    }


    /* -------------------------------------------------------------------------- */
    /*                              Calc                                          */
    /* -------------------------------------------------------------------------- */
    calc_2 = (data) => {
        data.forEach((item, index) => {
            data[index].index = index;
            data[index].pnl = H1.PNL2(this.state.forex_price[item.name_by].price, item.price_entry, item.size, item.pos);
            data[index].roe = H1.ROE2(item.pnl, item.size);
        });
        this.setState({ signal_2: data });


    }



    /* -------------------------------------------------------------------------- */
    /*                              componentWillUnmount                          */
    /* -------------------------------------------------------------------------- */
    componentWillUnmount() {
        //his.Socket.close()

    }


    /* ------------------------------ change_filter ----------------------------- */
    change_filter = (e) => {
        H1.notfy_remove();


        $('.filter_option .' + [e.currentTarget.dataset.filter_name] + ' .btn').removeClass('active');
        e.target.classList.add('active');

        this.setState({
            filter: {
                ...this.state.filter,
                [e.currentTarget.dataset.filter_name]: e.currentTarget.dataset.filter_val
            }
        })

        H1.notfy_icon("اعمال تغیرات", '⚙️');

    }



    /* ------------------------------ add_to_alert ------------------------------ */
    add_to_alert = (e) => {


        $(e.target).attr('disabled', true);

        const self = this;
        let data = {
            signal_id: e.currentTarget.dataset.signal_id,
            action: e.currentTarget.dataset.type,
        };


        H1.notfy_promise(Send('page/signal/alert', data)).then(function () {
            self.componentDidMount();
            $(e.target).attr('disabled', false);
        });
    }

    /* ------------------------------- open_modal ------------------------------- */
    open_modal = (e) => {
        this.setState({ trade_modal: this.state.signals[e.currentTarget.dataset.signal_index] });
        $("#add_to_trade_price").val("")
        $("#add_to_trade_size").val("")
    }

    /* ------------------------------ add_to_trade ------------------------------ */

    add_to_trade = (e) => {
        const self = this;

        let data = {
            action: 'add',
            signal_id: this.state.trade_modal.id,
            signal_name_by: this.state.trade_modal.name_by,
            signal_name_to: this.state.trade_modal.name_to,
            level_count: this.state.trade_modal.level_count,
            pos: this.state.trade_modal.pos,
            add_to_trade_price: $("#add_to_trade_price").val(),
            add_to_trade_size: $("#add_to_trade_size").val(),
        };



        this.state.signals.filter(signal => signal.in_trade).length >= 3 ? H1.alert_warning('تعداد معاملات فعال شما بیشتر از 3 عدد نمیتواند باشد.')

            : !data.add_to_trade_price ? H1.alert_warning('قیمت ورود را وارد نمایید.')
                : !data.add_to_trade_size ? H1.alert_warning('مقدار وارد شده صحیح نمیباشد.')

                    : H1.notfy_promise(Send('page/wallet/trade', data, true)).then(function () {
                        self.componentDidMount();
                        $('.trade_modal').trigger('click')


                        $("#add_to_trade_price").val("")
                        $("#add_to_trade_size").val("")
                    });
    }



    /* ------------------------------ tools_calc_do ------------------------------ */

    tools_calc_do = (e) => {

        let valid = false;

        let data = {
            en_price: H1.fixNumbers($("#tools_calc_in_price").val()),
            level: H1.fixNumbers($("#tools_calc_level").val()),
            pos: $('.pos_radio_btns input[name="pos"]:checked').val(),
            tp: H1.fixNumbers($("#tools_calc_tp").val()),
            sp: H1.fixNumbers($("#tools_calc_sp").val()),
        };


        !data.en_price ? H1.alert_warning('قیمت ورود را وارد نمایید.')
            : !data.level || data.level <= 0 ? H1.alert_warning('لورج وارد شده از 1 کمتر میباشد.')
                : !data.pos ? H1.alert_warning('نوع پوزیشن معامله را وارد نمایید.')
                    : !data.tp ? H1.alert_warning('حد سود معامله را وارد نمایید.')
                        : !data.sp ? H1.alert_warning('حد ضرر معامله را وارد نمایید.')
                            : valid = true

        if (valid) {

            let risk = (data.en_price - data.sp) / data.en_price;
            risk = risk * 100 * data.level;
            risk = data.pos === "S" ? risk = risk.toFixed() * -1 : risk.toFixed();

            let tp_p = (((data.tp - data.en_price) / data.en_price) * 100 * (data.level)).toFixed(1);
            if (tp_p < 0) {
                tp_p = tp_p * -1;
            }

            this.setState({ tools_calc_risk: { risk: risk, tp_p: tp_p, } });
        }
    }







    /* -------------------------------------------------------------------------- */
    /*                                   render                                   */
    /* -------------------------------------------------------------------------- */

    render() {
        $(document).ready(function () {
        });

        H1.page_name("سیگنال");

        return (

            <div className="container-fluid signal_page">



                <div className="custom-tab-1">
                    <ul className="nav nav-tabs justify-content-center text-center border-0" role="tablist">
                        <li className="nav-item col-6">
                            <a className="btn btn-outline-primary w-100 active" data-toggle="tab" href="#filter_row_tab"><i className="fas fa-arrow-alt-circle-down "></i>نمایش کامل</a>
                        </li>
                        <li className="nav-item col-6" >
                            <a className="btn btn-outline-primary w-100 " data-toggle="tab" href="#all_row_tab"><i className="fas fa-arrow-alt-circle-down "></i>  نمایش لیستی   </a>
                        </li>


                    </ul>
                </div>

                <div className="tab-content pt-3">

                    <div className="tab-pane fade show active" id="filter_row_tab" role="tabpanel">




                        {/* <div className="filter_option pt-4">


                            <div className="row">


                                <div className="col-xl-4 col-lg-6 filter_row">
                                    <div className="box card position-relative type_trade">
                                        <h4 className="dark-bg-dark"><i className="far fa-ruler-combined mr-1"></i> نوع معامله </h4>
                                        <div className="btn-group mb-2 btn-group-lg card-body">
                                            <button onClick={this.change_filter} data-filter_name="type_trade" data-filter_val="S" className="btn btn-outline-primary active" type="button" data-step="sf1">
                                                <i className="fas fa-chevron-circle-up"></i>اسپات

                                                <span className="count">{this.state.signals.filter(signal => signal.type_trade === 'S').length}</span>
                                            </button>
                                            <button onClick={this.change_filter} data-filter_name="type_trade" data-filter_val="F" className="btn btn-outline-primary" type="button" data-step="sf2">
                                                <i className="fas fa-chevron-circle-up"></i>فیوچر

                                                <span className="count">{this.state.signals.filter(signal => signal.type_trade === 'F').length}</span>

                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-lg-6 filter_row">
                                    <div className="box card position-relative type_risk">
                                        <h4 className="dark-bg-dark"><i className="far fa-exclamation-triangle mr-1"></i> میزان ریسک </h4>
                                        <div className="btn-group mb-2 btn-group-lg card-body">
                                            <button onClick={this.change_filter} data-filter_name="type_risk" data-filter_val="L" className="btn btn-outline-primary active" type="button" data-step="sf3">
                                                <i className="fas fa-chevron-circle-up"></i>کم

                                                <span className="count">{this.state.signals.filter(signal => signal.type_trade === this.state.filter.type_trade && signal.type_risk === "L" && signal.type_time === this.state.filter.type_time).length}</span>

                                            </button>
                                            <button onClick={this.change_filter} data-filter_name="type_risk" data-filter_val="M" className="btn btn-outline-primary" type="button" data-step="sf4">
                                                <i className="fas fa-chevron-circle-up"></i>متوسط
                                                <span className="count">{this.state.signals.filter(signal => signal.type_trade === this.state.filter.type_trade && signal.type_risk === "M" && signal.type_time === this.state.filter.type_time).length}</span>

                                            </button>
                                            <button onClick={localStorage.getItem('d_mod') == true ? H1.demo_mod : this.change_filter} data-filter_name="type_risk" data-filter_val="H" className="btn btn-outline-primary" type="button" data-step="sf5">
                                                <i className="fas fa-chevron-circle-up"></i>زیاد
                                                <span className="count">{this.state.signals.filter(signal => signal.type_trade === this.state.filter.type_trade && signal.type_risk === "H" && signal.type_time === this.state.filter.type_time).length}</span>

                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-lg-6 filter_row" data-step="sf6">
                                    <div className="box card position-relative type_time" >
                                        <h4 className="dark-bg-dark"><i className="far fa-calendar-alt mr-1"></i> اهداف </h4>
                                        <div className="btn-group mb-2 btn-group-lg card-body">
                                            <button onClick={this.change_filter} data-filter_name="type_time" data-filter_val="L" className="btn btn-outline-primary px-2 active" type="button">
                                                <i className="fas fa-chevron-circle-up"></i>  هدف  <small>(1)</small>
                                                <span className="count">{this.state.signals.filter(signal => signal.type_trade === this.state.filter.type_trade && signal.type_risk === this.state.filter.type_risk && signal.type_time === "L").length}</span>
                                            </button>

                                            <button onClick={localStorage.getItem('d_mod') == true ? H1.demo_mod : this.change_filter} data-filter_name="type_time" data-filter_val="M" className="btn btn-outline-primary px-2" type="button">
                                                <i className="fas fa-chevron-circle-up"></i> هدف  <small>(2)</small>
                                                <span className="count">{this.state.signals.filter(signal => signal.type_trade === this.state.filter.type_trade && signal.type_risk === this.state.filter.type_risk && signal.type_time === "M").length}</span>
                                            </button>

                                            <button onClick={localStorage.getItem('d_mod') == true ? H1.demo_mod : this.change_filter} data-filter_name="type_time" data-filter_val="H" className="btn btn-outline-primary px-2" type="button">
                                                <i className="fas fa-chevron-circle-up"></i> هدف  <small>(3)</small>
                                                <span className="count">{this.state.signals.filter(signal => signal.type_trade === this.state.filter.type_trade && signal.type_risk === this.state.filter.type_risk && signal.type_time === "H").length}</span>
                                            </button>

                                        </div>
                                    </div>
                                </div>

                            </div>







                        </div> */}



                        <hr />

                        {localStorage.getItem('d_mod') == true &&
                            <div className="demo_mod_baneer"> <i className="far fa-exclamation-triangle mr-2"></i> به دلیل استفاده از نسخه رایگان اپلیکیشن، تعداد سیگنال های مورد نمایش محدود شده است. </div>
                        }




                        <div className="row mt-2 signal_sec">



                            {

                                this.state.crypto_price &&
                                //this.state.signals.filter(signal => signal.type_trade === this.state.filter.type_trade && signal.type_risk === this.state.filter.type_risk && signal.type_time === this.state.filter.type_time).map((item, index) => [
                                this.state.signals.map((item, index) => [
                                    <div className="col-xl-4 col-lg-6 signal_col mb-4">
                                        <div className="card text-white signal_row text-left">
                                            <div className="box text-left ">
                                                <div className={`card-header type_${item.pos} text-left `} >



                                                    {
                                                        item.pos === 'B' ?
                                                            <>

                                                                <span className="type" data-step="sb001_2">B</span>
                                                            </>

                                                            : item.pos === 'S' &&
                                                            <>

                                                                <span className="type" data-step="sb001_2">S</span>
                                                            </>
                                                    }


                                                    <div className="name col-8" data-step="sb001">  {item.name_by} / {item.name_to}


                                                        <span className="level">

                                                            {
                                                                item.type_trade === "S" ? "اسپات"
                                                                    : item.type_trade === "F" && item.level_type === "C" ? "کراس "
                                                                        : item.type_trade === "F" && item.level_type === "I" && "ایزوله "
                                                            }

                                                            {
                                                                item.type_trade === "F" &&
                                                                <kbd>{item.level_count}<small> X</small></kbd>
                                                            }

                                                        </span>
                                                    </div>



                                                    <div className="col-4 mini-action-box text-left px-xs-0">




                                                        {
                                                            !item.in_trade ?
                                                                <button onClick={this.open_modal} data-signal_index={item.index} data-toggle="modal" data-target="#add_trade_modal" title="افزودن به معاملات" data-step="sb003"><i className="far fa-plus-circle"></i></button>
                                                                : <button disabled className="disabled" title="در سبد معامله موجود است" data-step="sb003"><i className="far fa-plus-circle"></i></button>
                                                        }

                                                        {
                                                            !item.alert ?
                                                                <button onClick={this.add_to_alert} data-signal_id={item.id} data-type="add" title="فعال کردن اعلان" data-step="sb002"> <i className="far fa-bell silent"></i> </button>
                                                                : <button onClick={this.add_to_alert} data-signal_id={item.id} data-type="remove" title="غیر فعال کردن اعلان" data-step="sb002"><i className="far fa-bell-slash silent"></i> </button>
                                                        }




                                                    </div>




                                                </div>

                                                <div className="card-body text-left info_box px-3 py-4">

                                                    <div className="d-flex text-center">

                                                        <div className="flex-fill px-2" data-step="sb1">

                                                            <div className="key"> سود </div>

                                                            {Number(item.stus) !== 1 ?
                                                                <div className={`val ${item.pnl > 0 ? 'text-success' : 'text-danger'}`}>
                                                                    $ {formatCurrency(item.pnl, "USD", "en", true)}
                                                                </div>

                                                                :

                                                                <div className={`val`}>
                                                                    -
                                                                </div>
                                                            }

                                                        </div>

                                                        <div className="flex-fill px-2" data-step="sb2">
                                                            <div className="key"> درصد سود </div>


                                                            {Number(item.stus) !== 1 ?
                                                                <div className={`val ${Number(item.roe) > 0 ? 'text-success' : 'text-danger'}`}>
                                                                    {Number(item.roe).toFixed(2)}%
                                                                </div>


                                                                :

                                                                <div className={`val`}>
                                                                    -
                                                                </div>
                                                            }



                                                        </div>

                                                        <div className="flex-fill  px-2" data-step="sb3">
                                                            <div className="key"> ریسک </div>
                                                            <div className="val"> {item.risk}% </div>
                                                        </div>

                                                        {/* <div className="flex-fill px-2">
                                                <div className="key"> زمان ورود </div>
                                                <div className="val">   {item.time}  </div>
                                            </div> */}

                                                    </div>

                                                    <div className="d-flex text-center mt-2">


                                                        <div className="flex-fill col-4 px-2" data-step="sb4">
                                                            <div className="key"> قیمت ورود </div>
                                                            <div className="val"> {item.price_entry}  </div>
                                                        </div>

                                                        <div className="flex-fill col-4 px-2" data-step="sb5">
                                                            <div className="key">  مقدار <small> ({item.name_by}) </small>  </div>
                                                            <div className="val">  {item.size}  </div>
                                                        </div>
                                                        <div className="flex-fill col-4 px-2" data-step="sb6">
                                                            <div className="key">  مارجین <small> ({item.name_to}) </small> </div>
                                                            <div className="val"> {item.margin} </div>
                                                        </div>


                                                    </div>


                                                    <div className="d-flex text-center mt-2">

                                                        <div className="flex-fill col-4 px-2" data-step="sb7">
                                                            <div className="key"> قیمت مارکت </div>
                                                            <div className="val">
                                                                {formatCurrency(Number(this.state.crypto_price[item.name_by].price), "USD", "en", true)}
                                                            </div>
                                                        </div>
                                                        <div className="flex-fill col-4 px-2" data-step="sb8">
                                                            <div className="key"> حد ضرر قیمتی  </div>
                                                            <div className={`val`}>
                                                                {item.stop_loss} </div>
                                                        </div>

                                                        <div className="flex-fill col-4 px-2" data-step="sb9">
                                                            <div className="key"> حد سود قیمتی </div>
                                                            <div className={`val`}>
                                                                {item.take_profit} <small>(%{item.tp_p})</small> </div>
                                                        </div>

                                                    </div>



                                                    <div className="extra_sec stus" data-step="sb10">

                                                        {
                                                            item.stus == 1 ?
                                                                <>
                                                                    <div className="blob orange"></div>
                                                                    <span> صف انتظار </span>
                                                                </>

                                                                : item.stus == 3 ?
                                                                    <>
                                                                        <div className="blob blue"></div>
                                                                        <span> فعال:  <span className="signal_timer ltr d-inline-block"> {item.time}</span>  </span>

                                                                    </>

                                                                    : item.stus == 4 &&
                                                                    <>
                                                                        <div className="blob green"></div>
                                                                        <span> تمام شده  </span>

                                                                    </>

                                                        }


                                                    </div>



                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                ])


                            }


                            {this.state.forex_price &&

                                this.state.signal_2.map((item, index) => [
                                    <div className="col-xl-4 col-lg-6 signal_col mb-4">
                                        <div className="card text-white signal_row text-left">
                                            <div className="box text-left ">
                                                <div className={`card-header type_${item.pos} text-left `} >



                                                    {
                                                        item.pos === 'B' ?
                                                            <>

                                                                <span className="type" data-step="sb001_2">B</span>
                                                            </>

                                                            : item.pos === 'S' &&
                                                            <>

                                                                <span className="type" data-step="sb001_2">S</span>
                                                            </>
                                                    }


                                                    <div className="name col-8" data-step="sb001">  {item.name_by} / {item.name_to}</div>



                                                    {/* <div className="col-4 mini-action-box text-left px-xs-0">




                                                        {
                                                            !item.in_trade ?
                                                                <button onClick={this.open_modal} data-signal_index={item.index} data-toggle="modal" data-target="#add_trade_modal" title="افزودن به معاملات" data-step="sb003"><i className="far fa-plus-circle"></i></button>
                                                                : <button disabled className="disabled" title="در سبد معامله موجود است" data-step="sb003"><i className="far fa-plus-circle"></i></button>
                                                        }

                                                        {
                                                            !item.alert ?
                                                                <button onClick={this.add_to_alert} data-signal_id={item.id} data-type="add" title="فعال کردن اعلان" data-step="sb002"> <i className="far fa-bell silent"></i> </button>
                                                                : <button onClick={this.add_to_alert} data-signal_id={item.id} data-type="remove" title="غیر فعال کردن اعلان" data-step="sb002"><i className="far fa-bell-slash silent"></i> </button>
                                                        }




                                                    </div> */}




                                                </div>

                                                <div className="card-body text-left info_box px-3 py-4">

                                                    <div className="d-flex text-center">

                                                        <div className="flex-fill px-2" data-step="sb1">

                                                            <div className="key"> سود </div>

                                                            {Number(item.stus) !== 1 ?
                                                                <div className={`val ${item.pnl > 0 ? 'text-success' : 'text-danger'}`}>
                                                                    $ {formatCurrency(item.pnl, "USD", "en", true)}
                                                                </div>

                                                                :

                                                                <div className={`val`}>
                                                                    -
                                                                </div>
                                                            }

                                                        </div>

                                                        <div className="flex-fill px-2" data-step="sb2">
                                                            <div className="key"> درصد سود </div>


                                                            {Number(item.stus) !== 1 ?
                                                                <div className={`val ${Number(item.roe) > 0 ? 'text-success' : 'text-danger'}`}>
                                                                    {Number(item.roe).toFixed(2)}%
                                                                </div>


                                                                :

                                                                <div className={`val`}>
                                                                    -
                                                                </div>
                                                            }



                                                        </div>


                                                        {/* <div className="flex-fill px-2">
                                                                            <div className="key"> زمان ورود </div>
                                                                            <div className="val">   {item.time}  </div>
                                                                        </div> */}

                                                    </div>

                                                    <div className="d-flex text-center mt-2">


                                                        <div className="flex-fill col-4 px-2" data-step="sb4">
                                                            <div className="key"> قیمت ورود </div>
                                                            <div className="val"> $ {item.price_entry}  </div>
                                                        </div>

                                                        <div className="flex-fill col-4 px-2" data-step="sb5">
                                                            <div className="key"> قیمت مارکت </div>
                                                            <div className="val">
                                                                $ {formatCurrency(Number(this.state.forex_price[item.name_by].price), "USD", "en", true)}
                                                            </div>
                                                        </div>

                                                        <div className="flex-fill col-4 px-2" data-step="sb6">
                                                            <div className="key">  مقدار <small> (Lot) </small>  </div>
                                                            <div className="val">  {item.size}  </div>
                                                        </div>


                                                    </div>


                                                    <div className="d-flex text-center mt-2">


                                                        <div className="flex-fill px-2" data-step="sb8">
                                                            <div className="key"> حد ضرر قیمتی  </div>
                                                            <div className={`val`}>
                                                                {item.stop_loss ?? "‌"} </div>
                                                        </div>

                                                        <div className="flex-fill  px-2" data-step="sb9">
                                                            <div className="key"> حد سود قیمتی اول </div>
                                                            <div className={`val`}>
                                                                {item.take_profit}
                                                                {/* <small>(%{item.tp_p})</small> */}
                                                            </div>
                                                        </div>



                                                    </div>


                                                    <div className="d-flex text-center mt-2">

                                                        {item.take_profit_2 &&

                                                            <div className="flex-fill  px-2" data-step="sb9">
                                                                <div className="key"> حد سود قیمتی دوم </div>
                                                                <div className={`val`}>
                                                                    {item.take_profit_2}
                                                                    {/* <small>(%{item.tp_p})</small> */}
                                                                </div>
                                                            </div>


                                                        }



                                                        {item.take_profit_3 &&

                                                            <div className="flex-fill  px-2" data-step="sb9">
                                                                <div className="key">  حد سود قیمتی سوم </div>
                                                                <div className={`val`}>
                                                                    {item.take_profit_3}
                                                                    {/* <small>(%{item.tp_p})</small> */}
                                                                </div>
                                                            </div>


                                                        }


                                                    </div>






                                                    <div className="extra_sec stus" data-step="sb10">

                                                        {
                                                            item.stus == 1 ?
                                                                <>
                                                                    <div className="blob orange"></div>
                                                                    <span> صف انتظار </span>
                                                                </>

                                                                : item.stus == 3 ?
                                                                    <>
                                                                        <div className="blob blue"></div>
                                                                        <span> فعال:  <span className="signal_timer ltr d-inline-block"> {item.time}</span>  </span>

                                                                    </>

                                                                    : item.stus == 4 &&
                                                                    <>
                                                                        <div className="blob green"></div>
                                                                        <span> تمام شده  </span>

                                                                    </>

                                                        }


                                                    </div>



                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                ])

                            }






                            <div className="modal fade trade_modal" id="add_trade_modal">
                                <div className="modal-dialog modal-dialog-centered modal-dialog " role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title m-auto"><i className="far fa-user-chart mr-1"></i> افزودن سیگنال به سبد معامله  </h5>
                                            <button type="button" className="close" data-dismiss="modal"><span>&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">

                                            <div className="alert alert-outline-info px-3 px-3">
                                                <i className="fas fa-info-circle mr-1"></i>


                                                از 10% سرمایه خود استفاده کنید تا در صورت خطای سیگنال تنها {this.state.trade_modal.risk / 10}% از سرمایه خود را متضرر شوید.


                                            </div>

                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">   مقدار با لورج         <kbd className="ml-1 bg-dark ltr">{this.state.trade_modal.level_count}<small> X</small></kbd> </span>
                                                </div>
                                                <input type="number" id="add_to_trade_size" className="form-control ltr font-18" autoComplete="off" />
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">  <span id="add_to_trade_size_key">{this.state.trade_modal.name_by}</span>  </span>
                                                </div>
                                            </div>
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">قیمت ورود:</span>
                                                </div>
                                                <input type="number" id="add_to_trade_price" className="form-control ltr font-18" autoComplete="off" />
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"> {this.state.trade_modal.name_to}</span>
                                                </div>
                                            </div>


                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" id="add_to_trade_cancle" className="btn btn-danger light mr-auto" data-dismiss="modal">انصراف</button>
                                            <button type="button" id="add_to_trade_action" onClick={localStorage.getItem('d_mod') == true ? H1.demo_mod : this.add_to_trade} className="btn btn-primary px-5" > افزودن  <i className="fas fa-plus-hexagon ml-1"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>




                        </div>


                    </div>


                    <div className="tab-pane fade  " id="all_row_tab">
                        <div className="w-100">
                            <div className="card">
                                <div className="">
                                    <ul className="list-group">

                                        <li className="list-group-item signal_row signal_list_mod head">
                                            <div className="row box">
                                                <div className="col-4 col-lg-4"><div className="key p-1 text-center"> نام ارز</div></div>
                                                <div className="col-4 col-lg-4"><div className="key p-1 text-center">  سود </div></div>
                                                <div className="col-4 col-lg-4 px-0"><div className="key p-1 text-center"> درصد سود </div></div>
                                            </div>
                                        </li>




                                        {

                                            this.state.forex_price &&
                                            this.state.signal_2.map((item, index) => [


                                                <li className="list-group-item signal_row signal_list_mod ">

                                                    <div className="row box">


                                                        <div className="col-lg-4 col-4 br-l">

                                                            {item.name_by} / {item.name_to}

                                                        </div>


                                                        <div className="col-lg-4 col-4 br-l text-center px-1">

                                                            {Number(item.stus) !== 1 ?
                                                                <div className={`val ltr ${item.pnl > 0 ? 'text-success' : 'text-danger'} mt-0`}>
                                                                    $ {formatCurrency(item.pnl, "USD", "en", true)}
                                                                </div>

                                                                :

                                                                <div className={`val ltr mt-0`}>
                                                                    -
                                                                </div>
                                                            }

                                                        </div>

                                                        <div className="col-lg-4 col-4 text-center">

                                                            {Number(item.stus) !== 1 ?
                                                                <div className={`val ltr ${Number(item.roe) > 0 ? 'text-success' : 'text-danger'} mt-0`}>
                                                                    {Number(item.roe).toFixed(2)}%
                                                                </div>


                                                                :

                                                                <div className={`val ltr mt-0`}>
                                                                    -
                                                                </div>
                                                            }

                                                        </div>
                                                    </div>

                                                </li>



                                            ])

                                        }






                                        {
                                            this.state.crypto_price &&
                                            this.state.signals.map((item, index) => [


                                                <li className="list-group-item signal_row signal_list_mod ">

                                                    <div className="row box">


                                                        <div className="col-lg-4 col-6 br-l">

                                                            {
                                                                item.pos === 'B' ?
                                                                    <>

                                                                        <span className={`type px-2 text-white float-left mr-1 py-0 type_${item.pos}`} >B</span>
                                                                    </>

                                                                    : item.pos === 'S' &&
                                                                    <>

                                                                        <span className={`type px-2 text-white float-left mr-1 py-0 type_${item.pos}`} >S</span>
                                                                    </>
                                                            }


                                                            {item.name_by} / {item.name_to}

                                                            <span className="level">

                                                                {
                                                                    item.type_trade === "S" ? "اسپات"
                                                                        : item.type_trade === "F" && item.level_type === "C" ? "کراس "
                                                                            : item.type_trade === "F" && item.level_type === "I" && "ایزوله "
                                                                }

                                                                {
                                                                    item.type_trade === "F" &&
                                                                    <kbd>{item.level_count}<small> X</small></kbd>
                                                                }

                                                            </span>


                                                        </div>


                                                        <div className="col-lg-4 col-3 br-l text-center">

                                                            {Number(item.stus) !== 1 ?
                                                                <div className={`val ltr ${item.pnl > 0 ? 'text-success' : 'text-danger'}`}>
                                                                    $ {formatCurrency(item.pnl, "USD", "en", true)}
                                                                </div>

                                                                :

                                                                <div className={`val ltr`}>
                                                                    -
                                                                </div>
                                                            }

                                                        </div>

                                                        <div className="col-lg-4 col-3 text-center">

                                                            {Number(item.stus) !== 1 ?
                                                                <div className={`val ltr ${Number(item.roe) > 0 ? 'text-success' : 'text-danger'}`}>
                                                                    {Number(item.roe).toFixed(2)}%
                                                                </div>


                                                                :

                                                                <div className={`val ltr`}>
                                                                    -
                                                                </div>
                                                            }

                                                        </div>
                                                    </div>

                                                </li>



                                            ])
                                        }

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>






                <div className="float_tools" data-toggle="modal" data-target="#tools_modal"><i className="fas fa-calculator"></i></div>





                <div className="modal fade trade_modal " id="tools_modal" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-dialog " role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title m-auto"><i className="fas fa-calculator-alt mr-1"></i> ماشین حساب معاملات</h5>
                                <button type="button" className="close" data-dismiss="modal"><span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">



                                    <div className="col-12">
                                        <div className="input-group mb-4">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">قیمت ورود:</span>
                                            </div>
                                            <input type="number" id="tools_calc_in_price" className="form-control ltr font-18" autoComplete="off" />
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"> USDT</span>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-6">
                                        <div className="input-group mb-3 ">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">  لورج  </span>
                                            </div>
                                            <input type="number" id="tools_calc_level" className="form-control ltr font-18 text-center" autoComplete="off" min="1" defaultValue="1" />

                                        </div>
                                    </div>



                                    <div className="col-6">

                                        <div className="form-group mt-3 pos_radio_btns">

                                            <div className="custom-control custom-radio custom-control-inline mr-4">
                                                <input type="radio" id="pos_radio_1" name="pos" value="B" className="custom-control-input" />
                                                <label className="custom-control-label" htmlFor="pos_radio_1">BUY</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="pos_radio_2" name="pos" value="S" className="custom-control-input" />
                                                <label className="custom-control-label" htmlFor="pos_radio_2">SELL</label>
                                            </div>
                                        </div>


                                    </div>

                                    <div className="col-12">
                                        <div className="input-group mb-4">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">حد سود قیمتی:</span>
                                            </div>
                                            <input type="number" id="tools_calc_tp" className="form-control ltr font-18" autoComplete="off" />

                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="input-group mb-4">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">حد ضرر قیمتی:</span>
                                            </div>
                                            <input type="number" id="tools_calc_sp" className="form-control ltr font-18" autoComplete="off" />

                                        </div>
                                    </div>

                                </div>



                                {
                                    this.state.tools_calc_risk &&

                                    <div className="row">

                                        <div className="col-12 col-md-6">
                                            <div className={`text-center alert alert-${this.state.tools_calc_risk.risk < 15 ? 'success bg-success' : this.state.tools_calc_risk.risk >= 16 && this.state.tools_calc_risk.risk <= 30 ? 'info bg-info' : this.state.tools_calc_risk.risk >= 31 && this.state.tools_calc_risk.risk <= 45 ? 'warning bg-warning' : this.state.tools_calc_risk.risk > 45 ? 'danger bg-danger' : "dark bg-dark"}  px-3 px-3 text-white`}>

                                                مقدار ریسک: <b className="ml-2">% {this.state.tools_calc_risk.risk}</b>

                                            </div>
                                        </div>



                                        <div className="col-12 col-md-6">
                                            <div className={`text-center alert alert-${this.state.tools_calc_risk.tp_p > 0 ? 'success bg-success' : this.state.tools_calc_risk.tp_p < 0 ? 'danger bg-danger' : "dark bg-dark"}  px-3 px-3 text-white`}>

                                                حد سود: <b className="ml-2"> % {this.state.tools_calc_risk.tp_p}</b>

                                            </div>
                                        </div>

                                    </div>
                                }

                            </div>

                            <div className="modal-footer">
                                <button type="button" id="add_to_trade_cancle" className="btn btn-danger light mr-auto" data-dismiss="modal">بستن</button>
                                <button type="button" id="add_to_trade_action" onClick={this.tools_calc_do} className="btn btn-primary px-5" > محاسبه  </button>
                            </div>
                        </div>
                    </div>
                </div>








            </div >



        )
    }
}

