import React from 'react';
import ReactDOM from 'react-dom';
import Sidebar from './layout/Sidebar';
import Main from './layout/Main';
import Login from './layout/Login';
import Package from './layout/Package';
import Header from './layout/Header';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

// import { Sendrequest, onMessageListener } from './firebase';

//import runOneSignal from './onesignal';
//runOneSignal();


//OneSignal.init({ appId: 'a8bd65e7-28a4-4784-9277-1c71b65356ba' });

// Sendrequest();

// onMessageListener()
// .then((payload) => {
// console.log(payload)    
// })
// .catch((err) => console.log('failed: ', err));


// Tooltips
// When users are not yet subscribed
// عضویت جهت دریافت اعلان ها
// When users are subscribed
// .شما برای دیافت اعلان‌ها عضو هستید
// When users have blocked notifications
// شما مجوز دریافت اعلان هارو بلاک کرده اید.
// Messages
// After users subscribe to notifications
// تشکر برا عضویت.
// When users choose to unsubscribe
// شما دریافت اعلان ها را غیر فعال کردید.
// Dialogs
// Title of the dialog shown when subscribed
// مدریت اعلان ها
// Buttons in the dialog shown when subscribed / unsubscribed
// غیرفعال
// عضویت 
// Title on the dialog shown when users block notifications
// اجازه دریافت اعلان
// Message shown when users have blocked notifications
// این دستور عمل ها را برای دریافت اعلان، دنبال کنید 




  const requireAuth = () => {

    let subleft = Math.round( ( localStorage.getItem('sub') -  Math.floor(new Date() / 1000) ) / 86400);
    if(subleft > 0){
      localStorage.setItem('sub_left', subleft )
    }
    else{
      localStorage.setItem('sub_left', 0)
    }


  if( localStorage.getItem('token') && localStorage.getItem('exp') > Math.floor(new Date() / 1000) ) {

 
    if( localStorage.getItem('sub') > Math.floor(new Date() / 1000)   ){
     return   <React.StrictMode>  <BrowserRouter> <Sidebar /> <Main /> </BrowserRouter> </React.StrictMode>
    }

    else{
      return   <BrowserRouter>  <Header />  <Package />  </BrowserRouter> 
    }

  }
  else  {

    localStorage.clear();

    return   <Login /> 


   }
  // stay on this route since the user is authenticated

}

requireAuth();

ReactDOM.render(


[

  requireAuth()

],


  document.getElementById('wrapper')
);

serviceWorker.register();
document.getElementById("preloader_2").style.display = 'none';

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
