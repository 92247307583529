import React from 'react';
import * as H1 from '../Helper/H1';
import Send from '../Helper/Send';
import $ from "jquery";




export default class Profile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            notfy: null,
        }
    }

    componentDidMount() {

        const self = this;
        let loader_1 = H1.notfy_loading();
        Send('page/notfy', null).then(function (res) {
            if (res) {
            self.setState({ notfy : res.data });
            H1.notfy_dismiss(loader_1);
            }
        });

    }


        /* ------------------------------ read_notfy  ------------------------------ */
        read_notfy = (e) => {

            const self = this;
            let data = {
                notfy_id: e.currentTarget.dataset.notfy_id,
            };
    
            H1.notfy_promise(Send('page/notfy/read', data)).then(function () {
                self.componentDidMount();
            });
        }
    


    render() {

        H1.page_name("اعلان ها");

        $(document).ready(function () {

        });

        return (


            <div className="container-fluid notfy_page">
                
                <div className="col-12">
                { this.state.notfy &&
                    <div className="card">
                        <div className="card-body">
                            <div id="DZ_W_TimeLine" className="widget-timeline ">
                                <ul className="timeline">
               
                                {this.state.notfy.map((item, index) => [

                                    <li>
                                        {
                                            item.read === true ?
                                            <div className="timeline-badge dark"></div>

                                            : <div className={`timeline-badge  ${item.type === 'alert' ? 'warning' : item.type === 'reward' ? 'success' :  item.type === 'sub' ? 'info' : 'dark'}`}></div>

                                        }
                                       

                                        <div className="timeline-panel text-muted" href="#">
                                            

                                            <span className="ltr float-right text-right">{item.created_at}
                                            
                                            {
                                            item.read === true &&
                                            <>
                                            <br/>(خوانده شده)
                                            </>
                                           }
                                            </span>
                                            <span>{item.date_diff}   

                                           </span>
                                            <h6 className="mb-2">{item.subject}</h6>
                                            <p className="mb-0 pr-5">{item.dis}</p>


                                            {
                                            item.read !== true &&
                                            <span className="read_btn" onClick={this.read_notfy} data-notfy_id={item.id} title="افزودن به خوانده شده"><i className="far fa-bookmark"></i> </span>
                                             }
                                        
                                        </div>
                                    </li>

                                ])}

                                </ul>
                            </div>
                        </div>
                    </div>
               }
                </div>
            </div>


        )
    }
}

