import React from 'react';
import * as H1 from '../Helper/H1';

export default class P_404 extends React.Component {

    render() {

        H1.page_name("خطای 404");



        return (


            <div className="page_404">
                <div className="flex-center position-r full-height">
                    <div className="code">
                        404 </div>

                    <div className="message">
                        چیزی یافت نشد! </div>
                </div>
            </div>


        )
    }
}

