import React from 'react';
import * as H1 from '../Helper/H1';
import Send from '../Helper/Send';
import $ from "jquery";
import QRCode from "react-qr-code";

export default class Package extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            packages: [],
            buy_modal: null,
            trust_wallet: null,
            transaction: null,
        }
    }

    componentDidMount() {


        const self = this;
        Send('page/plan/packages', null).then(function (res) {


            self.setState({ packages: res.data.packages });
            self.setState({ trust_wallet: res.data.wallet });
            self.setState({ transaction: res.data.transaction });


        });

    }


    /* ------------------------------- open_modal ------------------------------- */
    open_extra_modal = (e) => {
        this.setState({ buy_modal: this.state.packages[e.currentTarget.dataset.index] });
    }






    /* ------------------------------- trust_wallet_qr ------------------------------- */
    trust_wallet_qr = (e) => {
        $("#t_wallet_qr_box, #t_wallet_qr_btn").toggleClass("en");

    }


    /* -------------------------------  trust_wallet_copy ------------------------------- */
    trust_wallet_copy = (e) => {
        H1.Copy(this.state.trust_wallet);


    }








    /* ------------------------------ txid_submit ------------------------------ */

    txid_submit = (e) => {
        const self = this;

        let data = {
            type: this.state.buy_modal.type,
            txid: $("#txid").val(),
            accept_privacy: $("#accept_privacy").is(":checked"),
        };




        !data.txid ? H1.alert_warning('شناسه تراکنش را وارد نمایید')
            : data.txid.length !== 64 ? H1.alert_warning('TXID میبایست 64 رقم باشد.')
                : data.accept_privacy !== true ? H1.alert_warning('لطفا شرایط و قوانین را مطالعه و تایید نمایید.')


                    : Send('page/plan/transaction', data, "بررسی تراکنش ...").then(function (res) {
                        if (res) {


                            if (res.step === "S1") {
                                H1.alert_confirm('info', res.message, `<a class="btn light btn-primary" target="_blank" href="https://tronscan.org/#/transaction/${data.txid}">مشاهده تراکنش در سایت ترون</a>`);
                            }
                            else if (res.step === "S3") {

                                H1.alert_confirm('success', 'خرید با موفقیت انجام شد !', ` ${data.type} ماه به اشتراک شما اضافه شد.`).then(function (res2) {

                                    H1.new_token(res.data.token);
                                    window.location.reload();

                                });

                            }




                        }
                    });


    }




    render() {
        H1.page_name("اشتراک");


        return (


            <div className="container-fluid package_page">




                <div className="container">
                    <div className="row mt-5">


                        {this.state.packages.map((item, index) => [


                            <div className="col-xl-6 col-lg-12 col-sm-12 ">
                                <div className="card overflow-hidden">
                                    <div className="text-center p-3 overlay-box" >

                                        {/* {
                                            item.type === 12 ?
                                            <div className="row">
                                                    <div className="profile-photo col-lg-5 col-12">
                                                        <div className="num">
                                                            {item.type}
                                                        </div>
                                                        <h3 className="mt-3 mb-1 text-white">ماهه</h3>
                                                    </div>

                                                    <div className="profile-photo col-lg-2 col-12">
                                                        <div className="num d-none d-lg-block">
                                                          +
                                                        </div>
                                                        <h3 className="mt-3 mb-1 text-white d-block d-lg-none ">+</h3>

                                                    </div>


                                                    <div className="profile-photo col-lg-5 col-12">
                                                        <div className="num">
                                                           G7
                                                        </div>
                                                        <h3 className="mt-3 mb-1 text-white">پکیج آموزشی </h3>
                                                    </div>
                                             </div>
                                                :


                                        } */}



                                                <div className="profile-photo col-12">
                                                    <div className="num">
                                                        {item.type}
                                                    </div>
                                                    <h3 className="mt-3 mb-1 text-white">ماهه</h3>
                                                </div>



                                    </div>
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item text-center">


                                            <div className="price text-success">  <sup className='text-danger'> <del>{ Number( ( Number(item.price) / (100 - Number(item.discount)) ) * 100 ).toFixed(0) } </del></sup> {H1.formatNumber(item.price)}
                                                <span> USDT <img className="" src="https://s2.coinmarketcap.com/static/img/coins/64x64/825.png" width="64" height="64"></img></span>
                                            </div>


                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">
                                            <span className="mb-0">
                                                تخفیف:</span>
                                            <strong className="text-muted"><span className="badge badge-rounded badge-outline-success"> % {item.discount} </span></strong>
                                        </li>

                                        <li className="list-group-item d-flex justify-content-between ws_bs info_box">
                                            <span className="mb-0">
                                                {item.dis}

                                            </span>

                                        </li>
                                    </ul>
                                    <div className="card-footer border-0 mt-0">
                                        <button className="btn btn-primary btn-lg btn-block mt-2" onClick={this.open_extra_modal} data-toggle="modal" data-target="#extra_modal" data-index={index}>
                                            خرید اشتراک
                                            <i className="fas fa-arrow-circle-left ml-2"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>


                        ])}




                        {this.state.buy_modal &&
                            <div className="modal fade trade_modal extra_modal plan_buy_modal c-modal" id="extra_modal">
                                <div className="modal-dialog modal-dialog-centered modal-dialog  modal-lg" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title m-auto">  تمدید اشتراک ویژه {this.state.buy_modal.type} ماهه </h5>
                                            <button type="button" className="close" data-dismiss="modal"><span>&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="alert alert-outline border px-3 px-3">
                                                <i className="fas fa-info-circle mr-1"></i>
                                                جهت پرداخت: <br /> دقیقا مبلغ  <b className="px-2 text-danger ltr">{this.state.buy_modal.price} USDT </b>  را از طریق شبکه <b className="px-2 text-danger ltr"> TRC20 </b> به ادرس ولت زیر ارسال کنید.
                                                <br />
                                                سپس برای تکمیل فرایند، شناسه 64 رقمی تراکنش <b className="px-2 text-danger ltr">( TXID ) </b> را در کادر مورد نظر ثبت نمایید.
                                            </div>





                                            <div className="mb-3 p-relative">

                                                <div className="p-2 t_wallet_qr" id="t_wallet_qr_box">
                                                    <QRCode value={this.state.trust_wallet} size="150" />
                                                </div>


                                                <input type="text" className="form-control t_wallet ltr " defaultValue={this.state.trust_wallet} value={this.state.trust_wallet} autoComplete="off" readOnly />
                                                <div className="t_wallet_ac">
                                                    <span onClick={this.trust_wallet_qr} id="t_wallet_qr_btn"><small> QR </small> <i className="far fa-qrcode"></i> </span>
                                                    <span onClick={this.trust_wallet_copy}><i className="far fa-clone"></i> <small> کپی </small></span>
                                                </div>

                                            </div>




                                            <div className="alert alert-outline-warning border px-3 px-3">
                                                <i className="fas fa-exclamation-triangle mr-1"></i>
                                                توجه نمایید: <br /> محاسبه میزان کارمزد صرافی، مقدار ارسال و آدرس ولت گیرنده صحیح باشد. در صورت مغایرت در مقدار دریافت شده. شارژ خودکار اکانت انجام نمیشود.
                                                بیت باز هیچ مسئولیتی در قبال ارسال ارز به غیر از ولت معرفی شده  ندارد.
                                            </div>



                                            <div className="input-group mb-3 mt-1">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">TXID</span>
                                                </div>
                                                <input type="text" id="txid" className="form-control ltr font-18" autoComplete="off" placeholder='شناسه 64 رقمی تراکنش را وارد نمایید' />
                                            </div>

                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="accept_privacy" />
                                                <label className="form-check-label" htmlFor="accept_privacy">

                                                    تمامی  <a className="text-info" href="https://bitbaaz.com/terms-privacy" rel="help" target="_blank">شریط و قوانین</a> استفاده از اپلیکشین را خوانده ام و تمامی آن ها را می پذیرم.

                                                </label>
                                            </div>

                                        </div>

                                        <div className="modal-footer">
                                            <button type="button" id="add_to_trade_cancle" className="btn btn-danger light mr-auto" data-dismiss="modal">انصراف</button>
                                            <button type="button" id="add_to_trade_action" onClick={this.txid_submit} className="btn btn-primary px-5" > تکمیل خرید  <i className="far fa-shopping-cart ml-1"></i></button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        }
















                    </div>





                    <div className="row mt-5">
                        <h4 className="">تاریخچه اشتراک ها</h4>

                        <div className="card w-100">

                            <div className="card-body">
                                <div className="table-responsive rounded">
                                    <table className="table table-responsive-md">
                                        <thead className="hidden-xs">
                                            <tr>
                                                <th>#</th>
                                                <th>پکیج</th>
                                                <th>قیمت</th>
                                                <th>تاریخ </th>
                                                <th>شناسه واریز TXID </th>


                                            </tr>
                                        </thead>
                                        <tbody>


                                            {

                                                this.state.transaction &&
                                                this.state.transaction.map((item, index) => [


                                                    <tr>
                                                        <th className="hidden-xs">{index + 1}</th>

                                                        <td className="border-xs-table"> <span className="xs_lab"> پکیج</span> {item.plan_type} <sub>ماهه</sub> </td>
                                                        <td> <span className="xs_lab"> قیمت</span> {item.price} <sub>USDT</sub> </td>
                                                        <td> <span className="xs_lab"> تاریخ </span> {item.created_at} </td>
                                                        <td> <span className="xs_lab"> TXID </span>  {item.txid} <small className="cursor ml-2 opacity-5" onClick={() => H1.Copy(item.txid)}><i className="far fa-clone"></i> </small> </td>




                                                    </tr>

                                                ])}




                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>










                </div>
            </div>

        )
    }
}

